import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Logo from "../../../photos/EPICLogos/hero-image.png";

const useStyles = makeStyles(theme => ({
  header: {
    margin: "0px 10px",
    marginBottom: "50px",
    padding: "8px 0px",
    backgroundColor: "var(--grey)",
    width: "90%",
    margin: "0 auto",
    fontWeight: "200",
    /* font-style: "italic", */
    textAlign: "center",
    borderRadius: "0 0 15px 15px",
    fontSize: "clamp(40px, 6vw, 72px)",
    textTransform: "uppercase",
  },
  logo: {
    display: "block",
    width: "90%",
    margin: "0px auto",
  },
  acronym: {
    "&:hover": {
      textDecoration: "underline",
      color: "#ED6A5A",
      // color: "#937666",
    },
  },
  tabEmphasis: {
    textDecoration: "underline",
    "&:hover": {
      color: "blue",
      cursor: "not-allowed",
    },
  },
  email: {
    color: "var(--green)",
  },
  center: {
    textAlign: "center",
    margin: "30px 75px",
  },
  centerMobile: {
    textAlign: "justify",
    width: "95%",
    // backgroundColor: "salmon",
    margin: "1rem auto",
  },
  homeWelcome: {
    width: "min(900px, 95vw)",
    margin: "0 auto",
    boxSizing: "border-box",
    color: "var(--blue)",
  },
}));

export default function Header(props) {
  const classes = useStyles();

  return (
    <div className={classes.homeWelcome}>
      <div className={classes.homeWelcome}>
        <img alt="Logo" src={Logo} className={classes.logo} />
        <h1 className={classes.header}>
          <strong>{props.location}</strong>
        </h1>
      </div>
      <Hidden xsDown>
        <h2 className={classes.center}>
          Our clinics are staffed with some of the{" "}
          <span className={classes.tabEmphasis}>leading</span> Therapists in the
          Greater Puget Sound, who employ modern techniques in our
          state-of-the-art facilities.
        </h2>
        <p className={classes.center}>
          Our facility boasts state-of-the-art equipment coupled with the finest
          training and exercises. All of our Physical Therapists can help you
          gain greater mobility. If you are in need of specialized physical
          therapy we can discuss and gladly service those needs. Please do not
          hesitate to directly reach our{" "}
          {props.frontDeskEmail ? (
            <a
              href={`mailto:${props.frontDeskEmail}`}
              className={classes.email}>
              front desk
            </a>
          ) : (
            <span>director</span>
          )}{" "}
          to discuss your course of Rehabilatation.
        </p>
      </Hidden>
      <Hidden smUp>
        <h2 className={classes.centerMobile}>
          Our clinics are staffed with some of the{" "}
          <span className={classes.tabEmphasis}>leading</span> Therapists in the
          Greater Puget Sound, who employ modern techniques in our
          state-of-the-art facilities.
        </h2>
        <p className={classes.centerMobile}>
          Our facility boasts state-of-the-art equipment coupled with the finest
          training and exercises. All of our Physical Therapists can help you
          gain greater mobility. If you are in need of specialized physical
          therapy we can discuss and gladly service those needs. Please do not
          hesitate to directly reach our{" "}
          {props.frontDeskEmail ? (
            <a
              href={`mailto:${props.frontDeskEmail}`}
              className={classes.email}>
              front desk
            </a>
          ) : (
            <span>director</span>
          )}{" "}
          to discuss your course of Rehabilatation.
        </p>
      </Hidden>
    </div>
  );
}
