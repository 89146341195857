import "./style.css";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import PhotoContainer from "../../../../components/PhotoContainer";
import StockPhotoComponent from "../../../../components/StockPhotoComponent";
import Picture1 from "../../../../photos/StockPhotos/Picture1.jpg";
import Picture2 from "../../../../photos/StockPhotos/Picture2.jpg";
import Picture3 from "../../../../photos/StockPhotos/Picture3.jpg";

const useStyles = makeStyles({
  heading: {
    lineHeight: 1.3,
    fontSize: "28px",
    width: "max(340px, 70%)",
    margin: "20px auto",
    textDecoration: "underline",
  },
  mainText: {
    lineHeight: 1.3,
    fontSize: "24px",
    width: "max(340px, 70%)",
    margin: "30px auto",
  },
});

function HomePhilosophy() {
  const classes = useStyles();
  return (
    <>
      <div className="philosophy">
        <p>
          At EPIC Physical Therapy, who we are is spelled out in our motto. As a
          team of <i>"Experienced Professionals in Individualized Care,"</i> our
          purpose is to bring your life and personal wellness to a greater level
          so you can live each day to its fullest. Our care focuses on your
          goals in returning to an improved state of well being. Physical
          Therapy and recovery is a process and not an event. Find out more
          about what matters to you below.
        </p>
        <PhotoContainer width="50">
          <Hidden smDown>
            <StockPhotoComponent image={Picture1} altTag="stock" />
          </Hidden>
          <StockPhotoComponent image={Picture2} altTag="stock" />
          <Hidden smDown>
            <StockPhotoComponent image={Picture3} altTag="stock" />
          </Hidden>
        </PhotoContainer>
      </div>
      <div id="equipment-blurb-container">
        <h2 className={classes.heading}>Mission Statement</h2>
        <p className={classes.mainText}>
          Our mission is to provide excellent, accessible, professional and
          individualized care to enable our clients to reach their maximum
          potential for their individual physical needs and abilities.
        </p>
        <h2 className={classes.heading}>Vision of Excellence Statement</h2>
        <p className={classes.mainText}>
          Our vision is to be known as the premier and most accessible therapy
          practice, driven by excellent results with the most knowledgeable,
          ethical, friendly, diverse and efficient staff.
        </p>
      </div>
    </>
  );
}

export default HomePhilosophy;
