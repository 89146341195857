const treatmentData =
    [
        {
            id: 'pain-relief',
            tabTitle: 'Pain Relief',
            description: "EPIC Physical Therapy employs the most up-to-date and state-of-the-art pain relief and mobility modality treatments available. You won’t see a lot of unnecessary complex equipment in any of our clinics because we believe in providing solutions that can easily be reproduced at home."
        },
        {
            id: 'holistic-healing',
            tabTitle: 'Holistic Healing',
            description: "Our goal is to promote healing, reduce pain and set the course for you to become pain-free, active and truly independent once again! We also believe strongly in an integrative approach to treatment, working with other health-care professionals, and strive to keep abreast of new and innovative treatment options."
        },
        {
            id: 'lifestyle-approach',
            tabTitle: 'Lifestyle Approach',
            description: "Regaining an active and healthy lifestyle is the hallmark of what we do for you or your loved ones, and that is why our clients not only get better faster than the national average, but STAY BETTER LONGER! Getting better quicker is our goal for you but we understand that your personalized sessions are a process and not an event. We are committed to increasing your well being while setting Realistic Goals for YOU!"
        },
        {
            id: 'affordability',
            tabTitle: 'Affordability',
            description: "At EPIC Physical Therapy we are committed to making our care affordable so more people can gain access to our therapy services. As providers for most insurance companies EPIC will bill according to their scheduled fee payment scale. We also offer a financial hardship program AND a no-fee monthly payment program for those who simply need a helping hand."
        },
        {
            id: 'flexible-scheduling',
            tabTitle: 'Flexible Scheduling',
            // I am surprised this worked :O
            description: <FlexibleScheduling />
        },
        {
            id: 'offered-programs',
            tabTitle: 'Offered Programs',
            description: <OfferedPrograms />
        }
    ]

function PainRelief(){
    return (
        <>
            <h4>Pain Relief</h4>
            <p>EPIC Physical Therapy employs the most up-to-date and state-of-the-art pain relief and mobility modality treatments available. You won’t see a lot of unnecessary complex equipment in any of our clinics because we believe in providing solutions that can easily be reproduced at home.</p>
        </>
    )
}

function FlexibleScheduling() {
    return (
        <>
            <p>We make scheduling appointments easy and convenient for you with seven Physical Therapy Clinics around the Puget Sound region. We also have extended office hours from 8 a.m. to 7 p.m. for busy professionals, Monday through Friday. To change, cancel, or reschedule your appointment, our Office Staff will gladly assist you!</p>
            <h4>The greater the need, the greater the priority</h4>
            <p>If you are in extreme pain or recently had surgery, we make it a priority to accommodate you by scheduling your sessions promptly and getting you in to see you quickly - often on the same day! We recommend scheduling a series of appointments to stay on track with your physical rehabilitation or situation. This will help increase your recovery to greater well being.</p>
        </>
    )
}

function OfferedPrograms() {
    return (
        <>
            <h4>Our Programs in addition to Physical Therapy are:</h4>
            {/* 17 things */}
            <div id="offered-programs-list-container">
                <div className="offered-programs-list">
                    <ul>
                        <li>Work related injuries</li>
                        <li>Motor Vehicle Injuries</li>
                        <li>Sport Injuries & Prevention</li>
                        <li>Pre and Post Operative Rehabilitation</li>
                        <li>Vestibular/Vertigo</li>

                    </ul>
                </div>
                <div className="offered-programs-list">
                    <ul>
                        <li>Fall prevention</li>
                        <li>Headaches</li>
                        <li>Spine (Neck and Back)</li>
                        <li>Sacroiliac Dysfunction</li>
                    </ul>
                </div>
                <div className="offered-programs-list">
                    <ul>
                        <li>Shoulder Injuries</li>
                        <li>Elbow Injuries</li>
                        <li>Hip Injuries</li>
                        <li>Knee Injuries</li>
                    </ul>
                </div>
                <div className="offered-programs-list">
                    <ul>
                        <li>Foot and Ankle Injuries</li>
                        <li>Wellness/Strengthening Programs</li>
                        <li>Massage Therapy</li>
                        <li>Other Services</li>
                    </ul>
                </div>
            </div>
        </>
    )
}


export default treatmentData;