import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    header: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "4.5rem",
    },
    text: {
      textAlign: "center",
      marginBottom: "3rem",
    },
    table: {
      display: "flex",
      border: "1px solid white",
      flexDirection: "column",
      alignItems: "center",
      // justifyContent: "center",
    },
    row: {
      border: "1px solid white",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      // alignItems: "center",
    },
    columnHeading: {
      border: "1px solid white",
      background: "var(--green)",
      color: "black",
      width: "20%",
      display: "flex",
      paddingTop: "10px",
      paddingBottom: "10px",
      justifyContent: "center",
      alignItems: "center",
    },
    itemGrey: {
      display: "flex",
      border: "1px solid white",
      background: "var(--grey)",
      width: "20%",
      justifyContent: "center",
      alignItems: "center",
    },
    itemBlue: {
      display: "flex",
      border: "1px solid white",
      background: "var(--blue)",
      color: "white",
      width: "20%",
      justifyContent: "center",
      alignItems: "center",
    },
    clinicEmailBlue: {
      display: "flex",
    //   border: "1px solid white",
    //   background: "var(--blue)",
      color: "green",
    //   width: "20%",
    //   justifyContent: "center",
    //   alignItems: "center",
    },
    linkBlue: {
      color: "white",
      "&:visited": {
        color: "var(--cream)"
      },
      "&:hover": {
        background: "var(--cream)",
        color: "var(--green)"
      }
    },
    linkGrey: {
      color: "black",
      "&:visited": {
        color: "var(--cream)"
      },
      "&:hover": {
        background: "var(--cream)",
        color: "blue"
      }
    }
  }));

export default function LocationRowGrey(props) {
    const classes = useStyles();
    const {clinic, color} = props;
    if (color === "blue") {
        return (
            <tr className={classes.row}>
              <td className={classes.itemBlue}>
                <Typography variant="body1">
                  <Link className={classes.linkBlue} to={clinic.href}>
                    {clinic.clinic}
                  </Link>
                </Typography>
              </td>
              <td className={classes.itemBlue}>
                <Typography variant="body1">
                  <a
                    href={`mailto:${clinic.frontDeskEmail}`}
                    className={classes.clinicEmailBlue}
                  >
                    {clinic.frontDeskEmail}
                  </a>{" "}
                </Typography>
              </td>
              <td className={classes.itemBlue}>
                <Typography variant="body1">{clinic.address}</Typography>
              </td>
              <td className={classes.itemBlue}>
                <Typography variant="body1">{clinic.phNum}</Typography>
              </td>
              <td className={classes.itemBlue}>
                <Typography variant="body1">{clinic.director}</Typography>
              </td>
            </tr>
          );
    } else if (color === "grey") {

        return (
          <tr className={classes.row}>
                      <td className={classes.itemGrey}>
                        <Typography variant="body1">
                          <Link className={classes.linkGrey} to={clinic.href}>{clinic.clinic}</Link>
                        </Typography>
                      </td>
                      <td className={classes.itemGrey}>
                        <Typography variant="body1">
                          <a
                            href={`mailto:${clinic.frontDeskEmail}`}
                            className={classes.linkGrey}
                          >
                            {clinic.frontDeskEmail}
                          </a>{" "}
                        </Typography>
                      </td>
                      <td className={classes.itemGrey}>
                        <Typography variant="body1">
                          {clinic.address}
                        </Typography>
                      </td>
                      <td className={classes.itemGrey}>
                        <Typography variant="body1">{clinic.phNum}</Typography>
                      </td>
                      <td className={classes.itemGrey}>
                        <Typography variant="body1">{clinic.director}</Typography>
                      </td>
                    </tr>
        );
    }
}
