import React from "react";
import TextSplash from "./components/TextSplash";
import FAQ from "./components/FAQ";
import GetStarted from "./components/GetStarted";
import Locations from "./components/Locations";
import Forms from "./components/Forms";
// import { useSelector, useDispatch } from "react-redux";
// import { NAV_BTN } from "../actions";
import Container from "@material-ui/core/Container";

export default function Patients() {
  // const NavBtn = useSelector(state => state.NavBtn);
  // const dispatch = useDispatch();

  // const DoNav = () => {
  //   if (NavBtn === true) {
  //     dispatch(NAV_BTN());
  //   }
  // };

  // DoNav();

  return (
    <div id="top">
      <Container>
        <TextSplash />
        <GetStarted />
        <FAQ />
        <Locations />
        <Forms />
      </Container>
    </div>
  );
}
