import React from "react";
import { useStyles } from "../OfficeTemplate";

export default function ClinicDirector(props) {
  const {
    directorName,
    directorTitle,
    profession,
    directorEmail,
    directorPhoto,
  } = props;
  const mailTo = `mailto:${directorEmail}`;
  const classes = useStyles();
  return (
    <div className={classes.directorContainer}>
      <div className={classes.directorInfo}>
        <h1 className={classes.header}>Clinic Director</h1>
        {directorPhoto ? (
          <img
            className={classes.photoAndCaption}
            src={directorPhoto}
            alt={`${directorName} photo`}
          />
        ) : null}
        {directorName ? <p>{directorName}</p> : <p>Director TBA</p>}
        {directorTitle ? <p>{directorTitle}</p> : null}
        {profession ? <p>{profession}</p> : null}
        {directorEmail ? (
          <p>
            <a href={mailTo} className={classes.link}>
              {" "}
              {directorEmail}{" "}
            </a>
          </p>
        ) : null}
      </div>
    </div>
  );
}
