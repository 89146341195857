import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Home from "./pages/HomePage";
import Credits from "./pages/Credits";
import COVID from "./pages/COVID";
import Testimonials from "./pages/Testimonials";
import TacomaOffice from "./pages/Offices/Locations/Tacoma";
import FedOffice from "./pages/Offices/Locations/FederalWay";
import Bellevue from "./pages/Offices/Locations/Bellevue";
import Lakewood from "./pages/Offices/Locations/Lakewood";
import Olympia from "./pages/Offices/Locations/Olympia";
import Sumner from "./pages/Offices/Locations/Sumner";
import Puyallup from "./pages/Offices/Locations/Puyallup";
import Blogs from "./pages/Blogs";
import BlogCategories from "./pages/Blogs/components/BlogsSorted";
import Employees from "./pages/Employees";
import Physicians from "./pages/Physicians";
import Patients from "./pages/Patients";
import BlogOne from "./pages/Blogs/components/BlogOne";
// import BlogTwo from "./pages/blogs/BlogTwo";
import NoMatch from "./pages/NoMatch";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
// import OfficeTemplate from "./templates/OfficeTemplate";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
});

theme.typography.h1 = {
  fontSize: "2rem",
  textAlign: "center",
  padding: "2rem 0 2rem 0",
  background:
    "linear-gradient(60deg, #005596 45%, #8EC63F 90%, #00A5E0 95%, white)",
  marginBottom: "3rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "2rem",
    padding: "2rem 0 1rem 0",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
    padding: "2rem 0 1rem 0",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "3.5rem",
    padding: "2rem 0 0.8rem 0",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "6rem",
    padding: "2rem 0 1rem 0",
  },
  textDecoration: "none",
};

theme.typography.h2 = {
  fontSize: "1.5rem",
  textAlign: "center",
  padding: "3rem 0 2rem 0",
  marginBottom: "3rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.6rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.0rem",
    padding: "3rem 0 0.3rem 0",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.5rem",
    padding: "4rem 0 0.7rem 0",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "3.5rem",
    padding: "4.5rem 0 0.8rem 0",
  },
};

theme.typography.h3 = {
  fontSize: "1.3rem",
  textAlign: "center",
  padding: "2rem 0 1.6rem 0",
  marginBottom: "2.4rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.8rem",
    padding: "2.5rem 0 0.25rem 0",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.2rem",
    padding: "3rem 0 0.6rem 0",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "3rem",
    padding: "3.4rem 0 0.6rem 0",
  },
};

theme.typography.h4 = {
  fontSize: "1.1rem",
  textAlign: "center",
  padding: "1.4rem 0 1rem 0",
  marginBottom: "1.8rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.3rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem",
    padding: "2rem 0 0.3rem 0",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.7rem",
    padding: "2.5rem 0 0.5rem 0",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "2rem",
    padding: "3rem 0 0.55rem 0",
  },
};
theme.typography.h5 = {
  fontSize: "1.1rem",
  textAlign: "center",
  padding: "1rem 0 1rem 0",
  marginBottom: "1.4rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.3rem",
    padding: "2rem 0 0.3rem 0",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.4rem",
    padding: "2.5rem 0 0.5rem 0",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.5rem",
    padding: "3rem 0 0.55rem 0",
  },
};

theme.typography.subtitle1 = {
  fontSize: "1rem",
  textAlign: "left",
  marginBottom: "0.5rem",
  marginTop: "0.5rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.4rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.6rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.8rem",
  },
};

theme.typography.body1 = {
  fontSize: "1rem",
  textAlign: "left",
  marginBottom: "1rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.15rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.3rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.6rem",
  },
};

theme.typography.body2 = {
  fontSize: "0.8rem",
  textAlign: "left",
  marginBottom: "1rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.1rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.3rem",
  },
};

const App = () => {
  return (
    <Router>
      <Navbar />
      <ThemeProvider theme={theme}>
        <Switch>
          {/* <Route exact path="/appointments"></Route> */}
          <Route exact path="/covid">
            <COVID />
          </Route>
          {/* <Route exact path="/staff">
          <Staff />
        </Route> */}
          {/* <Route exact path="/office/:location">
          <Office />
        </Route> */}
          {/* Here just for testing. */}
          <Route exact path="/office/tacoma">
            <TacomaOffice />
          </Route>
          <Route exact path="/office/federal-way">
            <FedOffice />
          </Route>
          <Route exact path="/office/lakewood">
            <Lakewood />
          </Route>
          <Route exact path="/office/olympia">
            <Olympia />
          </Route>
          <Route exact path="/office/bellevue">
            <Bellevue />
          </Route>
          <Route exact path="/office/puyallup">
            <Puyallup />
          </Route>
          <Route exact path="/office/sumner">
            <Sumner />
          </Route>
          <Route exact path="/patients">
            <Patients />
          </Route>
          <Route exact path="/testimonials">
            <Testimonials />
          </Route>
          <Route exact path="/employees">
            <Employees />
          </Route>
          <Route exact path="/for-physicians">
            <Physicians />
          </Route>
          <Route exact path="/blog">
            <Blogs />
          </Route>
          <Route exact path="/blog/categories/:category">
            <BlogCategories />
          </Route>
          <Route exact path="/blog/the-good-condition">
            <BlogOne />
          </Route>
          {/* <Route exact path="/blog/2">
            <BlogTwo />
          </Route> */}
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/credits">
            <Credits />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
        <Footer />
      </ThemeProvider>
    </Router>
  );
};

export default App;
