import React from "react";
import { OfficeTemplate } from "../OfficeTemplate";
import ClinicDirector from "../components/ClinicDirector";
import Biography from "../components/Biography";
import Location from "../components/Location";
import { BellevueData } from "./locations";

const { directorData, biographyData, locationData } = BellevueData;

export default function Bellevue() {
  return (
    <OfficeTemplate
      location="Bellevue"
      frontDeskEmail={locationData.officeEmail}>
      <ClinicDirector {...directorData} />
      <Biography {...biographyData} />
      <Location {...locationData} />
    </OfficeTemplate>
  );
}
