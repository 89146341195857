import React from "react";
import {Link} from "react-router-dom";
import "./style.css";
import Logo from "../../photos/EPICLogos/hero-image.png";
import { makeStyles } from "@material-ui/core/styles";
// import { useSelector, useDispatch } from "react-redux";
// import { NAV_BTN } from "../actions";

const useStyles = makeStyles((theme) => ({
  link: {
    color: "white",
    textDecoration: "none"
  },
}));

export default function NoMatch() {
  const classes = useStyles();

  // const NavBtn = useSelector((state) => state.NavBtn);
  // const dispatch = useDispatch();

  // const DoNav = () => {
  //   if (NavBtn === true) {
  //     dispatch(NAV_BTN());
  //   }
  // };

  // DoNav();

  return (
    <div>
      <Link to="/" className={classes.link}>
        <div className="home-welcome">
        <img alt="Logo" src={Logo} id="logo" />
        <h1 ><strong>Page Not Found, Click Here to Return to Home</strong></h1>
        </div>
      </Link>
    </div>
  );
}
