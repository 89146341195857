import React from 'react'
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    stockPhoto: {
        width: '25rem',
        // height: '10rem'
    },
    Tab: {
        minWidth: 100, // a number of your choice
        width: 100, // a number of your choice
    }
});

export default function StockPhotoComponent(props) {
    const classes = useStyles()
    return (
        <img src={props.image} alt={props.altTag} className={classes.stockPhoto}/>
    )
}
