import dion from "../../photos/Credits/dion.jpeg"
import louis from "../../photos/Credits/louis.jpeg"
import ankush from "../../photos/Credits/ankush.jpeg"
import jess from "../../photos/Credits/jess.jpeg"
export const team = [
  {
    name: "Dion Leung",
    github: "dionleung14",
    linkedIn: "leungdion",
    emailAddy: "dioncleung@gmail.com",
    portfolioWebsite: "https://www.dionleung.engineer/",
    photo: dion,
    blurb1: "Dion served as the lead developer and project manager for this site.",
    blurb2: "A former civil and environmental engineer, Dion applies analytical reasoning and problem-solving skills learned from his previous profession to the world of technology.",
    blurb3: "Living near Seattle, WA, he enjoys taking out frustration on golf balls only to end up more frustrated than before starting.",
  },
  {
    name: "Louis Coleman",
    github: "coleloui",
    linkedIn: "louis-coleman",
    emailAddy: "coleloui18@gmail.com",
    portfolioWebsite: "https://louiscolemancoding.me/",
    photo: louis,
    blurb1: "A Tampa, FL native, Louis wore many hats but namely designed the contact forms.",
    blurb2: "He swears he's not a Tom Brady fan, but he hasn't denounced the Bucs for winning Super Bowl LV with TB12 at QB.",
    blurb3: ""
  },
  {
    name: "Megan Bryan",
    github: "",
    linkedIn: "",
    emailAddy: "",
    portfolioWebsite: "",
    photo: "",
    blurb1: "A physicist by training, Megan brings a fresh perspective to the world of web development.",
    blurb2: "Suspiciously, Megan has not responded to media requests ever since the disappearance of Amelia Earhardt. Also, Megan is a very strong swimmer...",
    blurb3: ""
  },
  {
    name: "Ankush Challa",
    github: "ankushchalla",
    linkedIn: "ankush-challa-a57ba1206",
    emailAddy: "",
    portfolioWebsite: "https://ankushchalla.github.io/Portfolio/",
    photo: ankush,
    blurb1: "Ankush is an applied mathematics graduate pivoting towards a career in technology.",
    blurb2: "It is still unknown whether Ankush was standing at a wooden wall or laying down on a pirate ship in the above photo.",
    blurb3: ""
  },
  {
    name: "Jessilin Lugo",
    github: "jessilinlugo",
    linkedIn: "jessilin-lugo-53090668",
    emailAddy: "jessilinlugo@gmail.com",
    portfolioWebsite: "https://jessilinlugo.com/",
    photo: jess,
    blurb1: "Jessilin is a 27 year old graphic designer based in San Bernardino, CA. Her specialties include the use of bright colors and bold lines in her designs, as well as the ability to adapt and produce different aesthetics and themes for varied design tastes.",
    // blurb1: "Jessilin is a talented developer, artist, and visual designer.",
    blurb2: "Being a talented developer, artist, and visual designer, Jessilin has been called the 'triple-threat' of front-end web development. Also, she has been accused of stealing lunch money from children in rigged competitions of speed-coding.",
    blurb3: ""
  },
  {
    name: "David Johnson",
    github: "DavidMark24",
    linkedIn: "davidjohnson24",
    emailAddy: "dmjwebapps@gmail.com",
    portfolioWebsite: "",
    photo: "",
    blurb1: "David is a web-developer by day and a web-developer by night.",
    blurb2: "David doesn't really get out much, preferring to chase the euphoria of getting code to work.",
    blurb3: ""
  },
  {
    name: "Tara Brichetto",
    github: "tbrichet",
    linkedIn: "tara-brichetto",
    emailAddy: "tara.brichetto@gmail.com",
    portfolioWebsite: "https://www.tarabrichetto.com/",
    photo: "",
    blurb1: "Tara is an Arizona native who enjoys traveling, gardening, and web development.",
    blurb2: "Nobody has ever photographed Tara and Batman at the same time or ever seen them together in the same room, so you can do the math.",
    blurb3: ""
  },
];

/*
Megan Bryan
Ankush Challa
Jess Lugo
JR
Tara Brichetto
*/