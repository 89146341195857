import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";

function getRandomString() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}

const placeholdersArr = [
  {
    name: "Mr. Rogers",
    email: "mister-rogers@neighborhood.com",
    title: "Appointment request",
    message: "Your message here",
  },
  {
    name: "Steve Rogers",
    email: "americas_captain@starkindustries.com",
    title: "Back tightness",
    message: "Lately my back has been hurting ",
  },
];

let randInd = Math.floor(Math.random() * placeholdersArr.length);

const initialFormData = {
  name: "",
  email: "",
  subject: "",
  message: "",
  title: "",
  clinic: "epictacoma@epic-pt.com",
};
Object.freeze(initialFormData);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    // flexWrap: "wrap",
    // width: "50%",
  },
  form: {
    background: "var(--blue)",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
    height: "auto",
    width: "60%",
    margin: "0 auto",
    padding: "0.25em 1em",
    color: "white",
    borderRadius: "5px",
  },
  formMobile: {
    background: "var(--blue)",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
    height: "auto",
    margin: "0 auto",
    padding: "0.25em 1em",
    color: "white",
    borderRadius: "5px",
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
    height: "auto",
    margin: "15px auto",
  },
  formHeader: {
    textAlign: "center",
    margin: "1rem auto",
    padding: "1rem 1.5rem",
    backgroundColor: "var(--blue)",
    width: "70%",
  },
  formDescription: {
    backgroundColor: "var(--blue)",
    width: "65%",
    border: "1px black solid",
    padding: "0.5em 0.75em",
    margin: "0px auto",
  },

  formLabel: {
    background: "var(--blue)",
    fontSize: "1.25em",
    padding: "0.5em",
  },
  formInput: {
    padding: "0.75em",
    fontSize: "1em",
    width: "auto",
    borderRadius: "10px",
    "&:focus": {
      background: "#eae4e1",
    },
  },
  formInputMsgContainer: {
    // backgroundColor: "red",
    // width: "100%",
    // overflow: "hidden"
  },
  formInputMsg: {
    fontSize: "1em",
    fontFamily: "Arial, Helvetica, sans-serif;",
    width: "96%",
    resize: "none",
    padding: "0.75em",
    borderRadius: "10px",
    "&:focus": {
      background: "#eae4e1",
    },
  },
  charCount: {
    backgroundColor: "var(--blue)",
    width: "45%",
    padding: "0.5em 0.75em",
    margin: "0px auto",
    textAlign: "center",
  },
  required: {
    color: "var(--green)",
  },
  sendBtn: {
    margin: "1rem auto",
    display: "block",
    borderRadius: "0.25em",
    padding: ".5rem 1rem",
    fontSize: "1.6em",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    background: "var(--green)",
    color: "white",
    "&:hover": {
      background: "#abe060",
      color: "white",
      textDecoration: "italics",
      cursor: "pointer",
    },
    "&:active": {
      background: "var(--grey)",
    },
  },
}));

export default function Contact() {
  const [contactSubject, setContactSubject] = React.useState(
    "New Message from Website"
  );
  const [formData, updateFormData] = React.useState({
    ...initialFormData,
  });
  const [bigWin, setBigWin] = React.useState("");

  const handleChange = (e) => {
    updateFormData({
      ...formData,

      [e.target.name]: e.target.value,
    });
  };

  const shortInsert = () => {
    setTimeout(emailNotification, 0);
    setTimeout(clearMe, 5000);
  };

  const emailNotification = () => {
    if (bigWin === "success") {
      return (
        <div id="success">
          <p>Your message has been sent!</p>
        </div>
      );
    } else if (bigWin === "error") {
      return (
        <div id="error">
          <p>An error occured while submitting the form.</p>
        </div>
      );
    } else return "";
  };

  const clearMe = () => {
    setBigWin("");
  };

  let characterLimit = 500;

  const classes = useStyles();

  return (
    <div className={classes.container} id="contact">
      <Hidden smDown>
        <Container>
          <Box my={3}>
            <form
              className={classes.form}
              id="contact"
              onSubmit={async (e) => {
                e.preventDefault();
                console.log(formData);
                formData.subject = contactSubject;
                setBigWin("");
                const res = await fetch("/.netlify/functions/contact", {
                  method: "POST",
                  body: JSON.stringify(formData),
                  headers: {
                    "Content-Type": "application/json",
                  },
                });
                if (res.ok) {
                  setBigWin("success");
                  updateFormData(() => initialFormData);
                  shortInsert();
                } else {
                  setBigWin("error");
                  shortInsert();
                }
                console.log("res", res);
              }}
            >
              <Typography variant="h2" className={classes.formHeader}>
                Contact us!
              </Typography>
              <input
                type="hidden"
                className="form-control"
                name={getRandomString()}
                autoComplete={getRandomString()}
                value={contactSubject}
                onChange={(e) => setContactSubject(e.target.value)}
                style={{ display: "none" }}
              />
              <div className={classes.formDiv}>
                <label htmlFor="name" className={classes.formLabel}>
                  Your name <span className={classes.required}>*</span>
                </label>
                <input
                  className={classes.formInput}
                  name="name"
                  required
                  type="text"
                  value={formData.name}
                  placeholder={placeholdersArr[randInd].name}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.formDiv}>
                <label htmlFor="email" className={classes.formLabel}>
                  Your email <span className={classes.required}>*</span>
                </label>
                <input
                  className={classes.formInput}
                  required
                  id="email"
                  type="email"
                  placeholder={placeholdersArr[randInd].email}
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.formDiv}>
                <label htmlFor="title" className={classes.formLabel}>
                  Subject <span className={classes.required}>*</span>
                </label>
                <input
                  className={classes.formInput}
                  id="title"
                  placeholder={placeholdersArr[randInd].title}
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.formDiv}>
                <label htmlFor="clinic" className={classes.formLabel}>
                  Office to Contact
                </label>
                <select
                  id="clinic"
                  name="clinic"
                  className={classes.formInput}
                  onChange={handleChange}
                >
                  <option value="epictacoma@epic-pt.com">Tacoma</option>
                  <option value="epicfw@epic-pt.com">Federal Way</option>
                  <option value="epiclakewood@epic-pt.com">Lakewood</option>
                  <option value="epicolympia@epic-pt.com">Olympia</option>
                  <option value="epicbellevue@epic-pt.com">Bellevue</option>
                  <option value="epicsumner@epic-pt.com">Sumner</option>
                  <option value="epicpuyallup@epic-pt.com">Puyallup</option>
                </select>
              </div>
              <div className={classes.formDiv}>
                <label htmlFor="message" className={classes.formLabel}>
                  Message <span className={classes.required}>*</span>
                </label>
                <div className={classes.formInputMsgContainer}>
                  <textarea
                    className={classes.formInputMsg}
                    rows="5"
                    maxLength={characterLimit}
                    name="message"
                    value={formData.message}
                    placeholder={placeholdersArr[randInd].message}
                    onChange={handleChange}
                  ></textarea>
                  <p className={classes.charCount}>
                    {formData.message.length
                      ? `Characters remaining: ${
                          characterLimit - formData.message.length
                        }`
                      : `Characters remaining: ${characterLimit}`}
                  </p>
                  <button className={classes.sendBtn} type="submit">
                    Send
                  </button>
                  <div>{emailNotification()}</div>
                </div>
              </div>
            </form>
          </Box>
        </Container>
      </Hidden>
      <Hidden mdUp>
        <Container>
          <Box my={3}>
            <form
              className={classes.formMobile}
              id="contact"
              onSubmit={async (e) => {
                e.preventDefault();
                console.log(formData);
                formData.subject = contactSubject;
                setBigWin("");
                const res = await fetch("/.netlify/functions/contact", {
                  method: "POST",
                  body: JSON.stringify(formData),
                  headers: {
                    "Content-Type": "application/json",
                  },
                });
                if (res.ok) {
                  setBigWin("success");
                  updateFormData(() => initialFormData);
                } else {
                  setBigWin("error");
                }
                console.log("res", res);
              }}
            >
              <Typography variant="h2" className={classes.formHeader}>
                Contact us!
              </Typography>
              <input
                type="hidden"
                className="form-control"
                name={getRandomString()}
                autoComplete={getRandomString()}
                value={contactSubject}
                onChange={(e) => setContactSubject(e.target.value)}
                style={{ display: "none" }}
              />
              <div className={classes.formDiv}>
                <label htmlFor="name" className={classes.formLabel}>
                  Your name <span className={classes.required}>*</span>
                </label>
                <input
                  className={classes.formInput}
                  name="name"
                  required
                  type="text"
                  value={formData.name}
                  placeholder={placeholdersArr[randInd].name}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.formDiv}>
                <label htmlFor="email" className={classes.formLabel}>
                  Your email <span className={classes.required}>*</span>
                </label>
                <input
                  className={classes.formInput}
                  required
                  id="email"
                  type="email"
                  placeholder={placeholdersArr[randInd].email}
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.formDiv}>
                <label htmlFor="title" className={classes.formLabel}>
                  Subject <span className={classes.required}>*</span>
                </label>
                <input
                  className={classes.formInput}
                  id="title"
                  placeholder={placeholdersArr[randInd].title}
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.formDiv}>
                <label htmlFor="clinic" className={classes.formLabel}>
                  Office to Contact
                </label>
                <select
                  id="clinic"
                  name="clinic"
                  className={classes.formInput}
                  onChange={handleChange}
                  value={formData.clinic}
                >
                  <option value="epictacoma@epic-pt.com">Tacoma</option>
                  <option value="epicfw@epic-pt.com">Federal Way</option>
                  <option value="epiclakewood@epic-pt.com">Lakewood</option>
                  <option value="epicolympia@epic-pt.com">Olympia</option>
                  <option value="epicbellevue@epic-pt.com">Bellevue</option>
                  <option value="epicsumner@epic-pt.com">Sumner</option>
                  <option value="epicpuyallup@epic-pt.com">Puyallup</option>
                </select>
              </div>
              <div className={classes.formDiv}>
                <label htmlFor="message" className={classes.formLabel}>
                  Message <span className={classes.required}>*</span>
                </label>
                <div className={classes.formInputMsgContainer}>
                  <textarea
                    className={classes.formInputMsg}
                    rows="5"
                    maxLength={characterLimit}
                    name="message"
                    value={formData.message}
                    placeholder={placeholdersArr[randInd].message}
                    onChange={handleChange}
                  ></textarea>
                  <p className={classes.charCount}>
                    {formData.message.length
                      ? `Characters remaining: ${
                          characterLimit - formData.message.length
                        }`
                      : `Characters remaining: ${characterLimit}`}
                  </p>
                  <button className={classes.sendBtn} type="submit">
                    Send
                  </button>
                  <div>
                    {bigWin === "success" && (
                      <div id="success">
                        <p>Your message has been sent!</p>
                      </div>
                    )}
                    {bigWin === "error" && (
                      <div id="error">
                        <p>An error occured while submitting the form.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </Box>
        </Container>
      </Hidden>
      <h3>Billing/Client Questions: Office= 253-448-2174, Fax= 253-448-2180</h3>
    </div>
  );
}
