import React from "react";
import { Link } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { NAV_BTN } from "../../actions";
import { makeStyles } from '@material-ui/core/styles';
import BlogsCategories from "./components/BlogsCategories"
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import blogPhoto from '../../photos/StockPhotos/blog-photo-1.png';
import './blogs.css';

const useStyles = makeStyles({
  root: {
    width: "20vw",
    backgroundColor: "var(--blue)",
    color: "white"
  },
  media: {
    height: 140,
  },
  name: {
    color: "var(--green)"
  }
});

function EmployeeCard() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="https://via.placeholder.com/150"
          title="Contemplative Reptile"
        />
        <CardContent>
          <h4>Employee of the Month</h4>
          <h2 className={classes.name}>Employee Name</h2>
          <p className={classes.name}>Descriptive line</p>
          <p>Additinal info</p>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default function Blogs() {

  // const NavBtn = useSelector(state => state.NavBtn);
  // const dispatch = useDispatch();

  // const DoNav = () => {
  //   if (NavBtn === true) {
  //     dispatch(NAV_BTN());
  //   }
  // };

  // DoNav();

  return (
    <>
    <h1 className="header">Welcome to the Blog</h1>
    <div className="blog-page">
      <div className="main-content">
        <div className="blog-container">
          <img id="blog-image" src={blogPhoto}></img>
          <div className="article-preview">
            <Link className="article-link" to='/blog/the-good-condition'><h2 className="article-title">The Good Condition: The benefits of a physically active lifestyle</h2></Link>
            <p>The human body is a vessel that provides us with extraordinary abilities to think, feel,
          and move in ways that are seemingly limited only by our imaginations. Our bodies are put
          through stresses everyday. These stresses take their toll mentally, emotionally and physically.
          From commuting, working, choring, cleaning, taking care of kids, or other loved ones; we are in
          an almost continuous state of work and stress. It is important that we take care of all aspects of
          our lives whether they be Mental, Physical or Psychological. Today we will briefly discuss the
          Physical side of our lives and why it is important to maintain a healthy physical lifestyle to
          bolster our lives.
          </p>
          </div>
        </div>
      </div>

      <div className="sidebar">
        {/* TODO: Turn into drop down on mobile. Or maybe it can always be a drop down? */}
        <BlogsCategories />
      </div>

    </div>
    </>
  );
}
