import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
// import Box from "@material-ui/core/Box";
// import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    // width: "75%",
    margin: " 30px auto",
    justifyContent: "space-around",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    paddingBottom: "60px",
  },
  navButton: {
    background:
      "linear-gradient(60deg, #005596 45%, #8EC63F 90%, #00A5E0 95%, white)",
    borderRadius: 5,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 30,
    padding: "0 10px",
    "&:hover": {
      background:
        "linear-gradient(120deg, white 5%, #00a5e0 10%, #8ec63f 55%, #005596)",
      color: "white",
    },
  },
  headline: {
    textAlign: "center",
    fontWeight: "bold",
    // borderBottom: "5px solid",
    // borderBottomColor: "#005596",
    paddingBottom: "40px",
    background:
      "var(--blue)",
      color: "white"
  },
  notice: {
    textAlign: "center",
    fontWeight: "bold",
    borderBottom: "5px solid",
    borderBottomColor: "#005596",
  },
  bodyText: {
    fontSize: "20px",
    textAlign: "center",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "0px",
    paddingBottom: "20px",
  },
  important: {
    color: "red",
    fontWeight: "bold",
    // textDecoration: "underline",
    backgroundColor:"var(--grey)",
    paddingTop: "10px",
    margin: "20px"
  },
  largeImportant: {
    color: "red",
    fontWeight: "bold",
    // textDecoration: "underline",
    backgroundColor:"var(--grey)",
    paddingTop: "10px",
    margin: "20px",
    fontSize: "32px"
  },
  button: {
    background: "var(--green)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px var(--blue)",
    color: "var(--blue)",
    height: 48,
    padding: "0px 30px",
    height: "60px",
    lineHeight: "60px",
    textDecoration: "none",
    justifyContent: "center",
    fontSize: "20px",
    "&:hover": {
      // color: "#ab9387",
      background: "var(--blue)",
      color: "white",
      boxShadow: "0 3px 5px 2px var(--green)",
    },
  },
  link: {
    color: "var(--green)",
  },
  questionBox: {
    display: "flex",
    flexDirection: "column",
    // border: "black 1px solid",
    justifyContent: "center",
    alignItems: "center",
    margin: "2rem auto",
    padding: "2rem 2rem",
    backgroundColor: "var(--blue)",
    boxShadow: "0 3px 5px 2px var(--grey)",
    borderRadius: "15px 15px 15px 0px",
    color: "white",
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
  answerBox: {
    display: "flex",
    flexDirection: "column",
    // border: "black 1px solid",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "2rem auto",
    padding: "2rem 2rem",
    backgroundColor: "var(--grey)",
    boxShadow: "0 3px 5px 2px var(--blue)",
    borderRadius: "15px 15px 0px 15px",
    color: "black",
    fontFamily: ["Lato", "sans-serif"].join(","),
  }
}));

export default function Covid_Guidelines() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {/* <Typography variant="h3" className={classes.headline}>
        COVID GUIDELINES
      </Typography> */}
      <Container>
        {/* <Typography variant="h5" className={classes.largeImportant}>
          IF YOU ARE EXPERIENCING A THREATENING EMERGENCY, CALL 9-1-1 FOR ASSISTANCE.
        </Typography> */}
        <Typography variant="h4" className={classes.questionBox}>
          First and Foremost:{" "}
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          If you are showing any signs of cold-like symptoms, fever, any type of cough,
          or runny nose, please call and cancel your appointment - we can reschedule your visit after you have been
          symptom-free for 14 days.
          <br />
          <span>-THANK YOU-</span>
        </Typography>
        <Typography variant="h4" className={classes.questionBox}>
          Our Commitment to Your Safety
        </Typography>
        <br />
        <Typography variant="body1" className={classes.bodyText}>
          As a provider of life-enhancing services, we at EPIC Physical Therapy believe your personal safety and well-being is of the utmost importance. We follow rigorous safety protocols in our office and have also created this
          page to share our guidelines and CDC.GOV recommendations for 
          protecting your health during the Covid-19 outbreak.
        </Typography>
        <br />
        <Typography variant="h4" className={classes.questionBox}>
          Resources
        </Typography>
        <Typography variant="body2" className={classes.bodyText}>
          These links to COVID-19 information are provided as a courtesy.
          Visit{" "}
          <a
          className={classes.link}
            href="https://www.cdc.gov/"
            target="_blank"
            rel="noreferrer noopener">
            www.CDC.gov
          </a> for more information.
        </Typography>
        <div className={classes.btnContainer}>
          <a
            href="https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/index.html"
            target="_blank"
            rel="noreferrer noopener"
            className={classes.button}>
            Schools
          </a>
          <a
            href="https://www.cdc.gov/coronavirus/2019-ncov/community/clean-disinfect/index.html"
            target="_blank"
            rel="noreferrer noopener"
            className={classes.button}>
            Cleaning
          </a>
          <a
            href="https://www.cdc.gov/coronavirus/2019-ncov/travelers/index.html"
            target="_blank"
            rel="noreferrer noopener"
            className={classes.button}>
            Travel
          </a>
          <a
            href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Fcoronavirus%2F2019-ncov%2Fprepare%2Fprevention.html"
            target="_blank"
            rel="noreferrer noopener"
            className={classes.button}>
            Safety
          </a>
        </div>
        <Typography variant="h5" className={classes.largeImportant}>
          PLEASE NOTE:
        </Typography>
        <Typography variant="body2" className={classes.bodyText}>
          <p>
            The information provided on this page is intended for your safety and knowledge and does not constitute medical advice.
          </p>
          <p>
            Please always consult your physician first before making an appointment with our staff - we CANNOT accept appointments with patients showing any symptoms of cold, flu, fever, or other physical symptoms indicating you may be ill.
          </p>
          <p>
          We reserve the right to refuse service if you are showing symptoms of illness. This is to protect the local community & the community at large. If you are not feeling well, please contact your primary care physician.
          </p>
        </Typography>
      </Container>
    </div>
  );
}
