import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import FilesButtons from "../../../components/FilesButtons";
import NewPatientForm from '../../../files/NewPatientForm.pdf';
import SatisfactionSurvey from '../../../files/Patients/Satisfaction Survey.pdf';

const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    flexDirection: "column",
    // border: "red 1px solid",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "4.5rem",
    // backgroundColor: "#002e52",
    // flexWrap: "wrap",
    // width: "50%",
  },
  text: {
    textAlign: "center",
    marginBottom: "3rem",
  },
  link: {
    textDecoration: "none"
  },
  center: {
    textAlign: "center",
    justifyContent: "center",
    marginBottom: "30px"
  },
  button: {
    alignSelf: 'center',
    backgroundColor: '#8EC63F',
    hieght: '50px',
    fontSize: '18px',
    textTransform: 'none',
    border: '3px solid #003860',
    borderRadius: '10px',
    padding: '10px 20px',
    margin: "0px 40px",
    textTransform: "uppercase",
    '&:hover': {
        backgroundColor: '#abe060'
    }
  }
}));

export default function FAQ() {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h3" className={classes.header}>
        Forms
      </Typography>
      <div className={classes.center}>
        <FilesButtons file={NewPatientForm} label="Click here to fill out a New Patient Form" />
        <FilesButtons file={SatisfactionSurvey} label="Click here to fill out a Satisfaction Survey" />
      </div>
    </div>
  );
}
