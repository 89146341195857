import React from "react";
import { OfficeTemplate } from "../OfficeTemplate";
import ClinicDirector from "../components/ClinicDirector";
import Biography from "../components/Biography";
import Location from "../components/Location";
import { PuyallupData } from "./locations";

const { directorData, biographyData, locationData } = PuyallupData;

function PuyallupOffice() {
  return (
    <OfficeTemplate
      location="Puyallup"
      frontDeskEmail={locationData.officeEmail}>
      {/* <OfficeTemplate location="Puyallup"> */}
      <ClinicDirector {...directorData} />
      <Biography {...biographyData} />
      <Location {...locationData} />
    </OfficeTemplate>
  );
}

export default PuyallupOffice;
