import React from "react";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    // display: "flex",
    //   flexDirection: "column",
    //   justifyContent: "center",
    //   alignItems: "center",
    backgroundColor: "var(--cream)",
    // flexWrap: "wrap",
    border: "2px solid black",
    borderRadius: "0px 30px",
    boxShadow: "5px 3px var(--grey)",
    margin: "1rem",
    padding: "1rem",
    width: "25%",
    // height: "20rem",
  },
  name: {
    textAlign: "center",
  },
  photo: {
    width: "60%",
    // height: "60%",
    display: "block",
    borderRadius: "50%",
    border: "1px solid black",
    margin: "0.2rem auto",
  },
  linksContainer: {
    display: "flex",
    margin: "0.1rem",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexWrap: "wrap",
  },
  links: {
    display: "inline",
    margin: "0.1rem",
  },
  emailLink: {
    display: "block",
    margin: "0.1rem",
    textAlign: "center",
    // backgroundColor: "blue"
  },
}));

export default function PersonCard(props) {
  const classes = useStyles();
  const {
    name,
    github,
    linkedIn,
    emailAddy,
    portfolioWebsite,
    photo,
    blurb1,
    blurb2,
    blurb3,
  } = props.personInfo;
  return (
    // <Card>
    <div className={classes.container}>
      <h2 className={classes.name}>{name ? name : null}</h2>
      {photo ? (
        <img className={classes.photo} src={photo} alt="headshot" />
      ) : (
        <img
          className={classes.photo}
          src="https://loremflickr.com/400/400"
          alt="generic headshot"
        />
      )}
      <p>{blurb1 ? blurb1 : null}</p>
      <p>{blurb2 ? blurb2 : null}</p>
      <p>{blurb3 ? blurb3 : null}</p>
      <div className={classes.linksContainer}>
        {portfolioWebsite ? (
          <span className={classes.links}>
            <a
              href={portfolioWebsite}
              target="_blank"
              rel="noreferrer noopener"
              >
              Portfolio
            </a>
          </span>
        ) : null}
        {linkedIn ? (
          <span className={classes.links}>
            <a
              href={`https://www.linkedin.com/in/${linkedIn}/`}
              target="_blank"
              rel="noreferrer noopener"
              >
              LinkedIn
            </a>
          </span>
        ) : null}
        {github ? (
          <span className={classes.links}>
            <a
              href={`https://github.com/${github}/`}
              target="_blank"
              rel="noreferrer noopener"
            >
              GitHub
            </a>
          </span>
        ) : null}
      </div>
      <span className={classes.emailLink}>
        {emailAddy ? <a href={`mailto:${emailAddy}`}>{emailAddy}</a> : null}
      </span>
    </div>
    // </Card>
  );
}
