import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ContactForm from "../../components/ContactForm";
import TestimonialForm from "./components/TestimonialForm";
// import testimonialsList from "../components/testimonialspage/testimonialList";
// import TestimonialsComponent from "../components/testimonialspage/TestimonialsComponent";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
// import { useSelector, useDispatch } from "react-redux";
// import { NAV_BTN } from "../actions";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "3.5rem",
    backgroundColor: "var(--green)",
    height: "60px",
    lineHeight: "60px",
    color: "var(--blue)",
    textTransform: `uppercase`,
  },
  text: {
    textAlign: "center",
    marginBottom: "3rem",
  },
  greyBackground: {
    backgroundColor: "var(--blue)",
    color: "white",
    padding: "3.5rem",
    borderRadius: "0 0 15px 15px",
  },
}));

export default function Testimonials() {
  const classes = useStyles();

  // const NavBtn = useSelector((state) => state.NavBtn);
  // const dispatch = useDispatch();

  // const DoNav = () => {
  //   if (NavBtn === true) {
  //     dispatch(NAV_BTN());
  //   }
  // };

  // DoNav();

  return (
    <div>
      <Container className={classes.text}>
        {/* <Typography variant="h2" className={classes.header}><strong>
          Testimonials
          </strong>
        </Typography>
        <Typography variant="h5" className={classes.greyBackground}>
          We could go on all day about how great our entire team is, but hearing
          it from us seems kind of boring. Read what patients like you have said
          about us below!
        </Typography>
        {testimonialsList.map((item) => {
          return (
            <TestimonialsComponent
              text={item.testimonial}
              author={item.author}
              date={item.date}
            />
          );
        })} */}
        {/* <Typography variant="h5">
          Are you a patient and would like to leave a review to be featured here? Contact us below!
        </Typography> */}
        <Typography variant="h5">
          Your feedback will help improve our Full Recovery Focus.
        </Typography>
        <Typography variant="h5">
          At EPIC physical therapy, we love to hear from our patients. We value your opinion and feedback and invite
          you to share the experience you had with our care. Your review will help us continue improving
          on our treatment and care to help serve our community better.
        </Typography>
        <Typography variant="h3">
          Thank you for choosing and trusting us for your physical therapy care!
        </Typography>
        <TestimonialForm />
      </Container>
    </div>
  );
}
