import React from "react";
import "./appointment.css";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  // If you want to override Material-UI stuff, you have to use JSS.
  button: {
    alignSelf: "center",
    backgroundColor: "#8EC63F",
    height: "75px",
    fontSize: "18px",
    textTransform: "none",
    border: "3px solid #003860",
    borderRadius: "10px",
    padding: "10px 20px",
    margin: "0px 40px",
    "&:hover": {
      backgroundColor: "#abe060",
    },
  },
});

function ScheduleAppointment() {
  const classes = useStyles();
  return (
    <div className="schedule-appointment">
      <p className="goal">
        We're here to help you relax, release, and regain a healthy active
        lifestyle through personalized physical therapy.
      </p>
      <Link to="/contact">
        <Button className={classes.button} variant="contained">
          {" "}
          SCHEDULE AN APPOINTMENT
        </Button>
      </Link>
    </div>
  );
}

export default ScheduleAppointment;
