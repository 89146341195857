import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import FacebookIcon from "@material-ui/icons/Facebook";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  footergroup: {
    display: "flex",
    justifyContent: "space-evenly",
    backgroundColor: "var(--grey)",
    height: "auto",
    paddingBottom: 25,
    paddingTop: 40,
    zIndex: 5,
  },
  fbLink: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-evenly",
    paddingRight: "10px",
    textAlign: "left",
    color: "var(--blue)",
    textDecoration: "none",
    paddingLeft: "10px",
    "&:hover": {
      color: "var(--green)",
    },
  },
  facebookMenuLink: {
    color: "black",
    textDecoration: "none",
    cursor: "pointer",
    padding: "0.5rem 1rem",
    margin: "0.4rem 1rem",
    border: "1px solid black",
    borderRadius: "5px",
    "&:hover": {
      color: "white",
      borderColor: "white",
    },
  },
  employeeLink: {
    padding: "0.5rem 1rem",
    margin: "0.4rem 1rem",
    border: "1px solid black",
    color: "black",
    textDecoration: "none",
    borderRadius: "5px",
    "&:hover": {
      color: "white",
      borderColor: "white",
    },
  },
  disabledLink: {
    padding: "0.5rem 1rem",
    margin: "0.4rem 1rem",
    border: "1px solid black",
    cursor: "not-allowed",
    borderRadius: "5px",
  },
}));

export default function Footer() {
  const classes = useStyles();

  const [anchorElFacebook, setAnchorElFacebook] = useState(null);

  const handleClickFacebook = (event) => {
    setAnchorElFacebook(event.currentTarget);
  };

  const handleCloseFacebook = () => {
    setAnchorElFacebook(null);
  };

  return (
    <div className={classes.footergroup} id="footer">
      <Grid container justify="space-around">
        <Link className={classes.employeeLink} to="/credits">
          <div>Credits</div>
        </Link>
        <Link className={classes.employeeLink} to="/contact">
          Contact Us
        </Link>
        <div className={classes.disabledLink}>Careers</div>
        <div
          className={classes.facebookMenuLink}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClickFacebook}
        >
          Find us on FaceBook!
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorElFacebook}
          keepMounted
          open={Boolean(anchorElFacebook)}
          onClose={handleCloseFacebook}
        >
          <MenuItem onClick={handleCloseFacebook}>
            <a
              href="https://bit.ly/EpicPT_Tacoma"
              target="_blank"
              rel="noreferrer noopener"
              className={classes.fbLink}
            >
              <span>Tacoma</span>
              <div>
                <FacebookIcon />
              </div>
            </a>
          </MenuItem>
          <MenuItem onClick={handleCloseFacebook}>
            <a
              href="https://bit.ly/EpicPT_FedWay"
              target="_blank"
              rel="noreferrer noopener"
              className={classes.fbLink}
            >
              <span>Federal Way</span>
              <div>
                <FacebookIcon />
              </div>
            </a>
          </MenuItem>
          <MenuItem onClick={handleCloseFacebook}>
            <a
              href="https://www.facebook.com/Epic-Physical-Therapy-Lakewood-905700459805234/"
              target="_blank"
              rel="noreferrer noopener"
              className={classes.fbLink}
            >
              <span>Lakewood</span>
              <div>
                <FacebookIcon />
              </div>
            </a>
          </MenuItem>
          <MenuItem onClick={handleCloseFacebook}>
            <a
              href="https://bit.ly/EpicPT_Olympia"
              target="_blank"
              rel="noreferrer noopener"
              className={classes.fbLink}
            >
              <span>Olympia</span>
              <div>
                <FacebookIcon />
              </div>
            </a>
          </MenuItem>
          <MenuItem onClick={handleCloseFacebook}>
            <a
              href="https://bit.ly/EpicPT_Bellevue"
              target="_blank"
              rel="noreferrer noopener"
              className={classes.fbLink}
            >
              <span>Bellevue</span>
              <div>
                <FacebookIcon />
              </div>
            </a>
          </MenuItem>
        </Menu>
        <Link className={classes.employeeLink} to="/employees">
          For Employees
        </Link>
        <Link className={classes.employeeLink} to="/for-physicians">
          For Physicians
        </Link>
      </Grid>
      {/* <div>
        Graphic Content and copy writing is intellectual property of Epic
        Physical Therapy and connot be used or duplicated without written
        consent from UCN Biz Group and Epic Physical Therapy.
      </div> */}
    </div>
  );
}
