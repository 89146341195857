import React from "react";
import { Link, useParams } from "react-router-dom";
import BlogsCategories from "./BlogsCategories";
// import { useSelector, useDispatch } from "react-redux";
// import { NAV_BTN } from "../../../actions";
import "../blogs.css";
import blogPhoto from "../../../photos/StockPhotos/blog-photo-1.png";


export default function BlogCategories(props) {

  // const NavBtn = useSelector(state => state.NavBtn);
  // const dispatch = useDispatch();

  // const DoNav = () => {
  //   if (NavBtn === true) {
  //     dispatch(NAV_BTN());
  //   }
  // };

  // DoNav();

  let { category } = useParams();
  let categoryArr = category.split("-");
  let capitalizedCategoryArr = [];
  categoryArr.forEach(word => {
    let letters = word.split("");
    letters[0] = letters[0].toUpperCase();
    capitalizedCategoryArr.push(letters.join(""));
  });
  const finalCategory = capitalizedCategoryArr.join(" ");

  return (
    <div>
      <>
        <h1 className="header">Recent {finalCategory} blogs</h1>
        <div className="blog-page">
          <div className="main-content">
            <div className="blog-container">
              {
                finalCategory === "Sports" || finalCategory === "Exercises" ?
                  <>
                    <img id="blog-image" src={blogPhoto}></img>
                    <div className="article-preview">
                      <Link className="article-link" to='/blog/the-good-condition'><h2 className="article-title">The Good Condition: The benefits of a physically active lifestyle</h2></Link>
                      <p>The human body is a vessel that provides us with extraordinary abilities to think, feel,
                      and move in ways that are seemingly limited only by our imaginations. Our bodies are put
                      through stresses everyday. These stresses take their toll mentally, emotionally and physically.
                      From commuting, working, choring, cleaning, taking care of kids, or other loved ones; we are in
                      an almost continuous state of work and stress. It is important that we take care of all aspects of
                      our lives whether they be Mental, Physical or Psychological. Today we will briefly discuss the
                      Physical side of our lives and why it is important to maintain a healthy physical lifestyle to
                      bolster our lives.
                      </p>
                    </div>
                  </>

                  :

                  <><h2 className="article-title">Blogs coming soon!</h2></>

              }

            </div>
          </div>

          <div className="sidebar">
            {/* TODO: Turn into drop down on mobile. Or maybe it can always be a drop down? */}
            <BlogsCategories />
          </div>

        </div>
      </>
    </div>
  );
}
