import React, { useState } from "react";
import treatmentData from "./data";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import "./style.css";
import PhotoContainer from "../../../../components/PhotoContainer";
import StockPhotoComponent from "../../../../components/StockPhotoComponent";
import Picture4 from "../../../../photos/StockPhotos/Picture4.jpg";
import Picture5 from "../../../../photos/StockPhotos/Picture5.jpg";
import Picture6 from "../../../../photos/StockPhotos/Picture6.jpg";

const useStyles = makeStyles({
  treatmentNames: {
    width: "auto",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    backgroundColor: "var(--blue)",
  },
  button: {
    "&:hover": {
      backgroundColor: "var(--grey)",
      color: "black",
    },
  },
  backgroundGrey: {
    backgroundColor: "var(--grey)",
    paddingTop: "20px",
    paddingBottom: "40px",
  },
});

function Treatments() {
  const classes = useStyles();
  const [displayedTreatment, setDisplayedTreatment] = useState("pain-relief");

  const showTreatment = (e, id) => setDisplayedTreatment(id);

  return (
    <>
      <PhotoContainer width="50">
        <Hidden smDown>
          <StockPhotoComponent image={Picture4} altTag="stock" />
        </Hidden>
        <StockPhotoComponent image={Picture5} altTag="stock" />
        <Hidden smDown>
          <StockPhotoComponent image={Picture6} altTag="stock" />
        </Hidden>
      </PhotoContainer>
      <Container className="treatments-container">
        <AppBar position="static">
          <Toolbar className={classes.treatmentNames}>
            {treatmentData.map((data) => (
              <div className={data.id === displayedTreatment ? "active" : null}>
                <Button
                  className={classes.button}
                  id={data.id}
                  color="inherit"
                  onClick={(e) => showTreatment(e, data.id)}
                >
                  {data.tabTitle}
                </Button>
              </div>
            ))}
          </Toolbar>
        </AppBar>
        <div className={classes.backgroundGrey}>
          {treatmentData.map((data) => (
            <div
              className={
                data.id === displayedTreatment ? "treatment" : "hidden"
              }
            >
              {data.description}
            </div>
          ))}
        </div>
      </Container>
    </>
  );
}

export default Treatments;
