import BellevueBio from "../../../files/Clinic_Dir_Bio_Erdemar_Tayag_Bellevue_040520.pdf";
import NormanPhoto from "../../../photos/Headshots/Norman Fabula.jpg";
import TacomaBio from "../../../files/Clinic_Dir_Bio_Norman_Fabula_Tacoma_061621.pdf";
import JoeyHeadshot from "../../../photos/Headshots/Joey Jove.jpg";
import OlympiaBio from "../../../files/Clinic_Dir_Bio_Joey_Jove_Olympia_040620.pdf";
import KristinaPhoto from "../../../photos/Headshots/Kristina Bondar.jpg";

const TacomaData = {
  directorData: {
    directorName: "Norman Fabula, MPT",
    directorTitle: "Physical Therapist",
    profession: "Tacoma Clinic Director",
    directorEmail: "normanf@epic-pt.com",
    directorPhoto: NormanPhoto,
  },
  biographyData: {
    biographyText1:
      "Norman is from the Philippines and has been practicing as a Physical Therapist in the US for 15-years. He worked primarily in outpatient orthopedics of very diverse populations. He enjoys working with all types of injuries, but specializes in the evaluation and treatment of the neck and back, as well as ribs and sacroiliac joint dysfunctions. Norman's expertise is in using a holistic approach when evaluating and treating patients, with an emphasis on manual therapy techniques such as muscle energy, myofascial release, low amplitude mobilizations, and Strain-Counter-strain techniques.",
    biographyText2:
      "Norman has pursued extensive continuing education in order to provide clients with advanced therapy techniques. He is a Certified Orthopedic Manual Therapist (COMT), Orthopedic Movement Specialist (OMS), and is currently pursuing his certification as a Strain-Counter-Strain Specialist through the Jones Intsitute.",
    biographyLink:
      "https://86f49143-3b30-4a41-953b-7f6665121f99.filesusr.com/ugd/bac57f_012c7af880404f2aa1fc13b8daf74177.pdf",
    biographyFile: TacomaBio,
  },
  locationData: {
    googleMapsLink: "https://goo.gl/maps/QJ9Wt2kCiPPx7NNK8",
    address: "5006 Center St, Tacoma, WA 98409",
    officeEmail: "epictacoma@epic-pt.com",
    officePhone: "(253) 476-3333",
    fax: "(253) 476-3334",
  },
};

const BellevueData = {
  directorData: {
    directorName: "Erdemar S. Tayag, PT, DPT, GCS",
    directorTitle: "Physical Therapist",
    profession: "Bellevue Clinic Director",
    directorEmail: "erdemart@epic-pt.com",
  },
  biographyData: {
    biographyText1:
      "Erdemar has lived in Vancouver, BC for a decade and just moved to King County 5 years ago, continuing to enjoy living in the Pacific Northwest. I worked for another Physical Therapy Outpatient Clinic for 5 years; helping many of patients with various cases and different physical impairments, dysfunctions and disabilities.",
    biographyLink:
      "https://86f49143-3b30-4a41-953b-7f6665121f99.filesusr.com/ugd/bac57f_2ee7055659534846a2ab21c37786ffdd.pdf",
    biographyFile: BellevueBio,
  },
  locationData: {
    googleMapsLink: "https://goo.gl/maps/4JZpCiBoFiJTEDbU6",
    address: "13353 Bel-Red Rd, Bellevue, WA 98005",
    officeEmail: "epicbellevue@epic-pt.com",
    officePhone: "(425) 679-5996",
    fax: "(425) 968-7590",
  },
};

const FederalWayData = {
  directorData: {
    directorName: "James Lee, DPT",
    directorTitle: "Physical Therapist",
    profession: "Federal Way Clinic Director",
    // directorEmail: "erdemart@epic-pt.com",
    // directorEmail: "andrewp@epic-pt.com"
  },
  biographyData: {
    biographyText1:
      "James is a 2nd generation Korean-American that found his passion of helping others through movement and exercise with his own experience with rehabilitation. James is well-versed in helping patients with orthopedic, geriatric, and general neurological issues. Outside of the clinic, James loves to play golf, practice guitar, and travel.",
    // biographyText1:
    // "Andrew received his education from Andrews University in Michigan 2016 with honors (Doctoral of Physical Therapy) with honors in 2016 from university Having more than 4 years of diverse experiences in The Physical Therapy Profession he has become part of the Team at EPT.",
  },
  locationData: {
    googleMapsLink: "https://goo.gl/maps/AZNU6GhSC6AfK4UHA",
    address: "728 S 320th St Suite G, Federal Way, WA 98003",
    officeEmail: "epicfw@epic-pt.com",
    officePhone: "(206) 212-6352",
    fax: "(206) 249-8086",
  },
};

const LakewoodData = {
  directorData: {
    directorName: "Norman Fabula, MPT",
    directorTitle: "Physical Therapist",
    profession: "Lakewood Clinic Director",
    directorEmail: "normanf@epic-pt.com",
    directorPhoto: NormanPhoto,
    // directorName: "Dana M. Krank, PT, MS, CSCS, CertFDN (Physical Therapist)",
    // directorEmail: "epic.krank@gmail.com",
    // directorName: "Angel Mabini, MPT",
    // directorEmail: "angelm@epic-pt.com"
  },
  biographyData: {
    biographyText1:
      "Norman is from the Philippines and has been practicing as a Physical Therapist in the US for 15-years. He worked primarily in outpatient orthopedics of very diverse populations. He enjoys working with all types of injuries, but specializes in the evaluation and treatment of the neck and back, as well as ribs and sacroiliac joint dysfunctions. Norman's expertise is in using a holistic approach when evaluating and treating patients, with an emphasis on manual therapy techniques such as muscle energy, myofascial release, low amplitude mobilizations, and Strain-Counter-strain techniques.",
    biographyText2:
      "Norman has pursued extensive continuing education in order to provide clients with advanced therapy techniques. He is a Certified Orthopedic Manual Therapist (COMT), Orthopedic Movement Specialist (OMS), and is currently pursuing his certification as a Strain-Counter-Strain Specialist through the Jones Intsitute.",
    biographyLink:
      "https://86f49143-3b30-4a41-953b-7f6665121f99.filesusr.com/ugd/bac57f_012c7af880404f2aa1fc13b8daf74177.pdf",
    biographyFile: TacomaBio,
  },
  locationData: {
    googleMapsLink: "https://goo.gl/maps/qWQ4QGLch3TMdwGy8",
    address: "8511 S Tacoma Way Ste #303, Lakewood, WA 98499",
    officeEmail: "epiclakewood@epic-pt.com",
    officePhone: "(253) 426-1244",
    fax: "(253) 503-7565",
  },
};

const OlympiaData = {
  directorData: {
    directorName: "Joey Jove, PT",
    directorTitle: "Physical Therapist",
    profession: "Olympia Clinic Director",
    directorEmail: "joeyj@epic-pt.com",
    directorPhoto: JoeyHeadshot,
  },
  biographyData: {
    biographyText1:
      "Joey Jove is originally from Philippines and moved as a young child to California. After joining the army, he pursued his education where he studied and earned his Master’s in physical therapy from Loma Linda. Joey has been practicing as a physical therapist since 1997. He has worked in a variety of physical therapy settings such as outpatient and hospital outpatient.",
    biographyText2:
      "Joey is a graduate of Loma Linda University’s Physical Therapy program. He has extensive training in Functional Movement, Selective Functional Movement Screen, and is currently pursuing his certification as an Orthopedic Certified Specialist (OCS). He’s married with 2 young beautiful daughters and devotes his time being the best husband and father. Joey also has 20 years of martial arts training in several forms to include Thai boxing, Filipino martial arts, Kenpo karate, Kali, and Silat.",
    biographyFile: OlympiaBio,
    biographyLink:
      "https://86f49143-3b30-4a41-953b-7f6665121f99.filesusr.com/ugd/bac57f_012c7af880404f2aa1fc13b8daf74177.pdf",
  },
  locationData: {
    googleMapsLink: "https://goo.gl/maps/24T4BsVmo19WSbX4A",
    address: "3000 Limited Ln NW #100, Olympia, WA 98502",
    officeEmail: "epicoly@epic-pt.com",
    officePhone: "(360) 292-7245",
    fax: "(360) 292-7247",
  },
};

const PuyallupData = {
  directorData: {
    directorName: "Joey Jove, PT",
    directorTitle: "Physical Therapist",
    profession: "Puyallup Clinic Director",
    directorEmail: "joeyj@epic-pt.com",
    directorPhoto: JoeyHeadshot,
    // directorName: "Dana M. Krank, PT, MS, CSCS, CertFDN",
    // directorEmail: "danak@epic-pt.com"
  },
  biographyData: {
    biographyText1:
      "Joey Jove is originally from Philippines and moved as a young child to California. After joining the army, he pursued his education where he studied and earned his Master’s in physical therapy from Loma Linda. Joey has been practicing as a physical therapist since 1997. He has worked in a variety of physical therapy settings such as outpatient and hospital outpatient.",
    biographyText2:
      "Joey is a graduate of Loma Linda University’s Physical Therapy program. He has extensive training in Functional Movement, Selective Functional Movement Screen, and is currently pursuing his certification as an Orthopedic Certified Specialist (OCS). He’s married with 2 young beautiful daughters and devotes his time being the best husband and father. Joey also has 20 years of martial arts training in several forms to include Thai boxing, Filipino martial arts, Kenpo karate, Kali, and Silat.",
    biographyFile: OlympiaBio,
    biographyLink:
      "https://86f49143-3b30-4a41-953b-7f6665121f99.filesusr.com/ugd/bac57f_012c7af880404f2aa1fc13b8daf74177.pdf",
    // biographyText1: "Dana received his education from the University of Montana School of Physical Therapy and specializes in outpatient orthopaedic care. Recently he relocated from Las Vegas, Nevada and returning to his native place of his youth. He is glad to be back in the Northwest to embrace his love for the outdoors, Dana has been practicing physical therapy for 15 years, with an emphasis in outpatient othopaedics and sports medicine.",
  },
  locationData: {
    googleMapsLink: "https://goo.gl/maps/TqbTX9zwpgXoGF5t9",
    address: "16515 Meridian Ave E Ste 202B, Puyallup, WA 98375",
    officeEmail: "epicpuyallup@epic-pt.com",
    officePhone: "(253) 466-3178",
    fax: "(253) 466-3886",
  },
};

const SumnerData = {
  directorData: {
    directorName: "Kristina Bondar, DPT, PTA",
    directorTitle: "Physical Therapist",
    profession: "Sumner Clinic Director",
    directorEmail: "kbondar.epicpt@gmail.com",
    directorPhoto: KristinaPhoto,
    // directorName: "Dolores Barton, MPT, MBA, CFPS",
    // directorEmail: "doloresb@epic-pt.com"
  },
  biographyData: {
    biographyText1:
      "Kristina is originally from Russia but emigrated to the US as a child. She practiced as a physical therapist assistant for several years before deciding to pursue becoming a Doctor of Physical Therapy.",
    biographyText2:
      "She graduated from the University of St. Augustine in San Marcos, Ca with high honors. Her approach for every patient is methodical with a focus on finding the root cause. She uses the latest evidence, exercises, and techniques to get her patients back on their feet and doing what they love. She sees a wide variety of orthopedic injuries.",
    biographyText3:
      "She is married and has three beautiful daughters. She enjoys family time, working out and weightlifting, doing escape rooms with hubby, and traveling to see new places.",
    // biographyText1:
    //   "Dolores recently joined EPIC Physical Therapy as the Clinic Director in Sumner, WA. She has extensive experience in multiple areas of the profession including acute care, outpatient and sports therapy, worker's compensation, post-surgical, geriatrics, home health, skilled and assisted living facilities. Dolores enjoys working with a wide spectrum of the population to maximize their level of independence and fitness.",
    // biographyText2:
    //   "She is certified as a Falls Prevention Specialist and has a Master's degree in Business Administration with an emphasis in Healthcare Management. Dolores' emphasis for patient care utilizes treatment approaches with evidence-based research to provide high quality care. She believes strongly in providing education to her clients, in addition to the population at large, with goals to prevent injuries and facilitate optimal outcomes for patient independence with regard to function. Teaching clients the tools to continue self-maintenance will help prevent future injury and decline in function, maintaining independence for longer. Therapy needs to be approached from both sides for the most effective approach for reaching goals.",
    // biographyText3:
    //   "Dolores keeps active outside of work enjoying time with her dog in many activities, especially outdoors, to maintain mental and physical fitness. Dolores has been a runner for most of her life and achieved one of her goals of qualifying for and running in the Boston Marathon on her first attempt. Recently moving back to WA, she is able to reconnect with friends and family and enjoys quiet time working in her garden/yard, reading and home improvements.",
  },
  locationData: {
    googleMapsLink: "https://goo.gl/maps/MMWWXn1gA1hisiFD7",
    address: "5814 Graham Ave #101, Sumner, WA 98390",
    officeEmail: "epicsumner@epic-pt.com",
    officePhone: "(253) 891-7093",
    fax: "(253) 891-1033",
  },
};

export {
  TacomaData,
  BellevueData,
  FederalWayData,
  LakewoodData,
  OlympiaData,
  PuyallupData,
  SumnerData,
};
