import React from "react";
import Footer from "../../components/Footer";
import Logo from "../../photos/EPICLogos/hero-image.png";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  link: {
    color: "white",
    textDecoration: "none",
  },
}));

export default function Physicians() {
  const classes = useStyles();
  return (
    <div>
      <Link to="/" className={classes.link}>
        <div className="home-welcome">
          <img alt="Logo" src={Logo} id="logo" />
          <h1>
            <strong>
              This page is still under construction, check back soon! Click here
              to return home
            </strong>
          </h1>
        </div>
        <br />
        <br />
        <br />
        <br />
      </Link>
    </div>
  );
}
