import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  localContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "50px",
    // marginTop: "8rem",
    // border: "red 2px solid",
    justifyContent: "center",
    alignItems: "center",
    // flexWrap: "wrap",
    // width: "50%",
    backgroundColor: 'var(--green)',
    color: "white"
  },
  accordion: {
    display: "flex",
    margin: "auto 10px",
    flexDirection: "column",
    width: "70%",
    justifyContent: "center",
    alignItems: "center",
    background: 'var(--grey)',
    "&:hover": {
      background: 'var(--blue)',
      color: "white"
    },
  },
  accordionMobile: {
    display: "flex",
    margin: "auto 10px",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    background: 'var(--grey)',
    "&:hover": {
      background: 'var(--blue)',
      color: "white"
    },
    // paddingBottom: "1rem"
  },
  accordionSummary: {
    // backgroundColor: "green",
    // padding: "10px 100px",
    // width: "100%",
    // display: "flex",
    // textAlign: "center",
    // justifyContent: "center",
    // alignItems: "center",
    "&:hover": {
      backgroundColor: 'var(--blue)',
      color: "white",
    },
  },
  accordionTitle: {
    // backgroundColor: "blue",
    display: "flex",
    textAlign: "center",
    // margin: "auto 0",
    justifyContent: "center",
    alignSelf: "start",
    "&:hover": {
      // backgroundColor: "red",
    },
  },
  accordionDetails: {
    // display: "flex",
    margin: "0 auto",
    // border: "2px #003860 solid",
    // boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
    // flexDirection: "column",
    width: "75%",
    marginBottom: "1rem",
    // justifyContent: "center",
    // alignItems: "center",
  },
  header: {
    border: "black 2px solid",
  },
  imageHolder: {
    // height: 500,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 700,
    // background: "red",
    position: "relative",
    // padding: 10,
    // paddingBottom: 10,
    // paddingTop: 10,
    // paddingLeft: 10,
    // paddingRight: 10,
    // border: "2px black solid",
  },
  image: {
    // height: 90,
    width: "100%",
    // position: "absolute",
    // top: 0,
  },
  acronym: {
    textDecoration: "underline",
    color: "#ED6A5A",
    "&:hover": {
      color: "#937666",
      cursor: "pointer",
    },
  },
  insuranceTable: {
    // border: "1px black solid",
    textAlign: "center",
  },
  insuranceList: {
    border: "1px black solid",
  },
  contentHolder: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function Services() {
  const classes = useStyles();

  return (
    <div id="services" className={classes.localContainer}>
      <Container>
        <Typography variant="h3">
          Whether you are looking for injury prevention, health and wellness, or recovery from an injury, EPIC has you covered. Click below to find out more
          about our programs.
        </Typography>
      </Container>
      <Hidden mdDown>
        <Container>
          <Box my={3}>
            <Grid container justify="center">
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.accordionSummary}
                >
                  <Typography variant="subtitle1">
                    {/* <h3 className={classes.accordionTitle}> */}
                    Acute or Chronic Pain
                    {/* </h3> */}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Typography variant="body1">
                    No matter what your issue or where it is - we can help! If your pain is in:
                    <ul>

                      <li>Back / Neck </li>
                      <li>Foot / Ankle </li>
                      <li>Hand / Wrist</li>
                      <li>Headaches</li>
                      <li>Knee / Leg</li>
                      <li>Shoulder / Arm</li>
                    </ul>
                    Or if you've experienced:
                    <li>Car Accident</li>
                    <li>Limited Range of Motion</li>
                    <li>Pre- and Post- Op</li>
                    <li>Slip & Fall</li>
                    <li>Sports Injury</li>
                    <li>Work Accident</li>
                    <li>Vertigo</li>

                  </Typography>
                </AccordionDetails>
                {/* <h3>PRE & POST OPERATIVE REHABILITATION</h3>
            <p>
              Rehabilitation addresses injury and disease that involves muscle,
              bone, ligament, tendon and joints. Our services include treatments
              for a variety of diagnoses including virtually any injury/disorder
              affecting the musculo-skeletal system. E.P.I.C. - "Experienced
              Professionals in Individualized Care"
            </p> */}
              </Accordion>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.accordionSummary}
                >
                  <Typography variant="subtitle1">
                    {/* <h3 className={classes.accordionTitle}> */}
                    Orthopedic Rehabilitation
                    {/* </h3> */}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Typography variant="body1">
                    <ul>
                      <li>Acute Injury Rehabilitation</li>
                      <li>Chronic Injury Rehabilitation</li>
                      <li>Post-Operative Rehabilitation</li>
                      <li>​Pre-Operative Rehabilitation</li>
                      <li>​Spine Rehabilitation</li>
                      <li>​Total Joint Rehabilitation​</li>
                      <li>Work Injury Rehabilitation​​ </li></ul>
                  </Typography>
                </AccordionDetails>

              </Accordion>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.accordionSummary}
                >
                  <Typography variant="subtitle1">
                    {/* <h3 className={classes.accordionTitle}> */}
                    Physical Therapy (also part of our Orthopedic Rehabilitation)
                    {/* </h3> */}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Typography variant="body1">
                    <ul>
                      <li>Ankle/Foot Programs</li>
                      <li>Arthritis Management Programs</li>
                      <li>Balance Programs</li>
                      <li>Biomechanical Correction/Optimization Programs</li>
                      <li>Cervical (Neck) Spine Programs</li>
                      <li>Elbow/ Wrist/ Hand Programs</li>
                      <li>Hip Programs</li>
                      <li>Knee Programs</li>
                      <li>Back (Thoracic and Lumbar) Spine Programs</li>
                      <li>Pain Management Programs</li>
                      <li>Headache Programs</li>
                      <li>Postural Programs</li>
                      <li>Shoulder Programs</li>
                      <li>SI Joint Dysfunction Programs</li>
                      <li>Sports Specific Rehabilitation/ Performance Programs</li>
                    </ul>
                  </Typography>
                </AccordionDetails>

              </Accordion>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.accordionSummary}
                >
                  <Typography variant="subtitle1">
                    {/* <h3 className={classes.accordionTitle}> */}
                    Pre & Post Operative Rehabilitation
                    {/* </h3> */}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Typography variant="body1">
                    Rehabilitation addresses injury and disease that involves
                    muscle, bone, ligament, tendon and joints. Our services
                    include treatments for a variety of diagnoses including
                    virtually any injury/disorder affecting the musculo-skeletal
                    system.
                  </Typography>
                </AccordionDetails>
                {/* <h3>PRE & POST OPERATIVE REHABILITATION</h3>
            <p>
              Rehabilitation addresses injury and disease that involves muscle,
              bone, ligament, tendon and joints. Our services include treatments
              for a variety of diagnoses including virtually any injury/disorder
              affecting the musculo-skeletal system. E.P.I.C. - "Experienced
              Professionals in Individualized Care"
            </p> */}
              </Accordion>
              {/* </div> */}
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="sports-med-content"
                  id="sports-med"
                  className={classes.accordionSummary}
                >
                  <Typography variant="subtitle1">
                    Sports Medicine Physical Therapy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Typography variant="body1">
                    Whether your sport is golf, tennis, soccer, basketball,
                    running, skiing, or hiking – giving up your sport is most
                    likely not an option. The combination of exercises targeting
                    the athlete’s specific sport and/or mechanics of the sport
                    will make for the best end result.
                    {/* ​ Programs we offer in
                    this Department: Sports Injuries & Prevention Foot & Ankle
                    General Spine (Neck & Back) Individualized Exercise Program */}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="preventative-content"
                  id="preventative"
                  className={classes.accordionSummary}
                >
                  <Typography variant="subtitle1">
                    Preventative Therapy & Conditioning
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Typography variant="body1">
                    Using manual therapy and therapeutic exercise can help
                    reduce the possibility of injury. Learning where you are at
                    most risk for injury will determine the method of training
                    that will benefit the most.
                    {/* Programs we offer in this
                    Department: General Spine (Neck & Back) Foot & Ankle
                    Individualized Exercise Program Work Conditioning
                    Vestibular/Vertigo Lymphedema */}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="massage-content"
                  id="massage"
                  className={classes.accordionSummary}
                >
                  <Typography variant="subtitle1">
                    Massage Therapy (Medical & Relaxation)
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Typography variant="body1">
                    Relaxation Massage Therapy: With or without an injury,
                    massage will improve pain, stiffness, and reduced mobility, and
                    will aid in recovery from fatigue, stress or injury. ​ Medical
                    Massage Therapy:
                    {/* Programs we offer in this Department: Pre &
                    Post Operative Surgery Increasing Mobility Injuries: Sports
                    and Personal Injury Prevention Program General Spine (Neck &
                    Back) Foot & Ankle Orthopedic Injuries Recovery &
                    Rehabilitation Reduce Pain Strength & Function Work Related
                    - Auto Accident */}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="insurance-content"
                  id="insurance"
                  className={classes.accordionSummary}
                >
                  <Typography variant="subtitle1">
                    Insurance & Private Pay Accepted
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Typography variant="body1">
                    We are happy to work with the following
                    insurance providers.{" "}
                    <table>
                      <tr>
                        <td className={classes.insuranceList}>ACCI</td>
                        <td className={classes.insuranceList}>Aetna</td>
                        <td className={classes.insuranceList}>Amerigroup</td>
                        <td className={classes.insuranceList}>CHPW</td>
                      </tr>
                      <tr>
                        <td className={classes.insuranceList}>Cigna</td>
                        <td className={classes.insuranceList}>
                          Coordinated Care
                        </td>
                        <td className={classes.insuranceList}>
                          First Choice Health Plan
                        </td>
                        <td className={classes.insuranceList}>First Health</td>
                      </tr>
                      <tr>
                        <td className={classes.insuranceList}>
                          GreatWest Healthcare
                        </td>
                        <td className={classes.insuranceList}>
                          Group Health – First Choice
                        </td>
                        <td className={classes.insuranceList}>Healthnet</td>
                        <td className={classes.insuranceList}>Humana</td>
                      </tr>
                      <tr>
                        <td className={classes.insuranceList}>
                          Labor & Industries (Federal)
                        </td>
                        <td className={classes.insuranceList}>
                          Labor & Industries (WA State)
                        </td>
                        <td className={classes.insuranceList}>Medicaid</td>
                        <td className={classes.insuranceList}>Medicare</td>
                      </tr>
                      <tr>
                        <td className={classes.insuranceList}>
                          Molina Healthcare
                        </td>
                        <td className={classes.insuranceList}>Motor Vehicle</td>
                        <td className={classes.insuranceList}>NW Physicians</td>
                        <td className={classes.insuranceList}>PHCS</td>
                      </tr>
                      <tr>
                        <td className={classes.insuranceList}>PIP</td>
                        <td className={classes.insuranceList}>Primera</td>
                        <td className={classes.insuranceList}>Regence</td>
                        <td className={classes.insuranceList}>
                          Secure Horizons
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.insuranceList}>Self Insured</td>
                        <td className={classes.insuranceList}>
                          Third Party is Accepted
                        </td>
                        <td className={classes.insuranceList}>Tricare</td>
                        <td className={classes.insuranceList}>
                          United Healthcare
                        </td>
                      </tr>
                    </table>
                    If your insurance company is on this list, please bring your
                    insurance info to your appointment. ​ Our list of providers
                    continues to grow to better serve our clients.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="referrals-content"
                  id="referrals"
                  className={classes.accordionSummary}
                >
                  <Typography variant="subtitle1">
                    New Patients, Referrals, Prescriptions
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Typography variant="body1">
                    We are always welcoming new patients! Please read below for more
                    information on scheduling your first visit.
                    <h4>Can I receive
                      PT services without a physician’s referral?</h4>
                    <p>
                      Consumers are
                      not required to have a referral in order to receive physical
                      therapy services in the State of Washington. Physical
                      Therapy services may be obtained without a referral if you
                      are a private pay or a cash-paying client receiving health
                      and wellness services.
                    </p>
                    <p>
                      In addition, some insurance companies
                      will pay for an initial evaluation and/or treatment without
                      referral for reimbursement. Our office can call and verify
                      your insurance benefits.
                    </p>
                    <h4>
                      To Make an Appointment
                    </h4>
                    <p>
                      Please fill out the contact form below or call our office for the location that you prefer to visit. If you have been referred by your doctor, be sure to have the referral/prescription available when calling. If you haven’t seen your doctor yet, please contact us to determine if your insurance requires a referral for an initial evaluation. We can also assist in obtaining a referral for further treatment.
                    </p>
                    <p>
                      Additionally please have your
                      insurance information ready when you call, or include this information when using the contact form below. Check our list of Insurance Partners to see if we have a contract with your insurance company.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Box>
        </Container>
      </Hidden>
      <Hidden lgUp>
        <Box my={3}>
          <Grid container justify="center">
            <Accordion className={classes.accordionMobile}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionSummary}
              >
                <Typography variant="subtitle1">
                  {/* <h3 className={classes.accordionTitle}> */}
                  Acute or Chronic Pain
                  {/* </h3> */}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Typography variant="body1">
                  No matter what your issue or where it is - we can help! If your pain is in:
                  <ul>

                    <li>Back / Neck </li>
                    <li>Foot / Ankle </li>
                    <li>Hand / Wrist</li>
                    <li>Headaches</li>
                    <li>Knee / Leg</li>
                    <li>Shoulder / Arm</li>
                  </ul>
                  Or if you've experienced:
                  <li>Car Accident</li>
                  <li>Limited Range of Motion</li>
                  <li>Pre- and Post- Op</li>
                  <li>Slip & Fall</li>
                  <li>Sports Injury</li>
                  <li>Work Accident</li>
                  <li>Vertigo</li>
                </Typography>
              </AccordionDetails>
              {/* <h3>PRE & POST OPERATIVE REHABILITATION</h3>
            <p>
              Rehabilitation addresses injury and disease that involves muscle,
              bone, ligament, tendon and joints. Our services include treatments
              for a variety of diagnoses including virtually any injury/disorder
              affecting the musculo-skeletal system. E.P.I.C. - "Experienced
              Professionals in Individualized Care"
            </p> */}
            </Accordion>
            <Accordion className={classes.accordionMobile}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionSummary}
              >
                <Typography variant="subtitle1">
                  {/* <h3 className={classes.accordionTitle}> */}
                  Orthopedic Rehabilitation
                  {/* </h3> */}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Typography variant="body1">
                  <ul>
                    <li>Acute Injury Rehabilitation</li>
                    <li>Chronic Injury Rehabilitation</li>
                    <li>Post-Operative Rehabilitation</li>
                    <li>​Pre-Operative Rehabilitation</li>
                    <li>​Spine Rehabilitation</li>
                    <li>​Total Joint Rehabilitation​</li>
                    <li>Work Injury Rehabilitation​​ </li></ul>
                </Typography>
              </AccordionDetails>
              {/* <h3>PRE & POST OPERATIVE REHABILITATION</h3>
            <p>
              Rehabilitation addresses injury and disease that involves muscle,
              bone, ligament, tendon and joints. Our services include treatments
              for a variety of diagnoses including virtually any injury/disorder
              affecting the musculo-skeletal system. E.P.I.C. - "Experienced
              Professionals in Individualized Care"
            </p> */}
            </Accordion>
            <Accordion className={classes.accordionMobile}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionSummary}
              >
                <Typography variant="subtitle1">
                  {/* <h3 className={classes.accordionTitle}> */}
                  Physical Therapy (also part of our Orthopedic Rehabilitation)
                  {/* </h3> */}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Typography variant="body1">
                  <ul>
                    <li>Ankle/Foot Programs</li>
                    <li>Arthritis Management Programs</li>
                    <li>Balance Programs</li>
                    <li>Biomechanical Correction/Optimization Programs</li>
                    <li>Cervical (Neck) Spine Programs</li>
                    <li>Elbow/ Wrist/ Hand Programs</li>
                    <li>Hip Programs</li>
                    <li>Knee Programs</li>
                    <li>Back (Thoracic and Lumbar) Spine Programs</li>
                    <li>Pain Management Programs</li>
                    <li>Headache Programs</li>
                    <li>Postural Programs</li>
                    <li>Shoulder Programs</li>
                    <li>SI Joint Dysfunction Programs</li>
                    <li>Sports Specific Rehabilitation/ Performance Programs</li>
                  </ul>
                </Typography>
              </AccordionDetails>

            </Accordion>
            <Accordion className={classes.accordionMobile}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionSummary}
              >
                <Typography variant="subtitle1">
                  {/* <h3 className={classes.accordionTitle}> */}
                  Pre & Post Operative Rehabilitation
                  {/* </h3> */}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Typography variant="body1">
                  Rehabilitation addresses injury and disease that involves
                  muscle, bone, ligament, tendon and joints. Our services
                  include treatments for a variety of diagnoses including
                  virtually any injury/disorder affecting the musculo-skeletal
                  system.
                </Typography>
              </AccordionDetails>

            </Accordion>

            {/* </div> */}
            <Accordion className={classes.accordionMobile}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="sports-med-content"
                id="sports-med"
                className={classes.accordionSummary}
              >
                <Typography variant="subtitle1">
                  Sports Medicine Physical Therapy
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Typography variant="body1">
                  Whether your sport is golf, tennis, soccer, basketball,
                  running, skiing, or hiking – giving up your sport is most
                  likely not an option. The combination of exercises targeting
                  the athlete’s specific sport and/or mechanics of the sport
                  will make for the best end result.
                  {/* Programs we offer in this
                  Department: Sports Injuries & Prevention Foot & Ankle General
                  Spine (Neck & Back) Individualized Exercise Program */}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordionMobile}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="preventative-content"
                id="preventative"
                className={classes.accordionSummary}
              >
                <Typography variant="subtitle1">
                  Preventative Therapy & Conditioning
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Typography variant="body1">
                  Using manual therapy and therapeutic exercise can help reduce
                  the possibility of injury. Learning where you are at most risk
                  for injury will determine the method of training that will
                  benefit the most.
                  {/* Programs we offer in this Department:
                  General Spine (Neck & Back) Foot & Ankle Individualized
                  Exercise Program Work Conditioning Vestibular/Vertigo
                  Lymphedema */}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordionMobile}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="massage-content"
                id="massage"
                className={classes.accordionSummary}
              >
                <Typography variant="subtitle1">
                  Massage Therapy (Medical & Relaxation)
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Typography variant="body1">
                  Relaxation Massage Therapy: With or with out an injury,
                  massage will improve pain, stiffness, reduced mobility and
                  help recovery from fatigue, stress or injury. ​ Medical
                  Massage Therapy:
                  {/* Programs we offer in this Department: Pre &
                  Post Operative Surgery Increasing Mobility Injuries: Sports
                  and Personal Injury Prevention Program General Spine (Neck &
                  Back) Foot & Ankle Orthopedic Injuries Recovery &
                  Rehabilitation Reduce Pain Strength & Function Work Related -
                  Auto Accident */}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordionMobile}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="insurance-content"
                id="insurance"
                className={classes.accordionSummary}
              >
                <Typography variant="subtitle1">
                  Insurance & Private Pay Accepted
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Typography variant="body1">
                  We are happy to work with the following
                  insurance providers.{" "}
                  <table>
                    <tr>
                      <td className={classes.insuranceList}>ACCI</td>
                      <td className={classes.insuranceList}>Aetna</td>
                      <td className={classes.insuranceList}>Amerigroup</td>
                      <td className={classes.insuranceList}>CHPW</td>
                    </tr>
                    <tr>
                      <td className={classes.insuranceList}>Cigna</td>
                      <td className={classes.insuranceList}>
                        Coordinated Care
                      </td>
                      <td className={classes.insuranceList}>
                        First Choice Health Plan
                      </td>
                      <td className={classes.insuranceList}>First Health</td>
                    </tr>
                    <tr>
                      <td className={classes.insuranceList}>
                        GreatWest Healthcare
                      </td>
                      <td className={classes.insuranceList}>
                        Group Health – First Choice
                      </td>
                      <td className={classes.insuranceList}>Healthnet</td>
                      <td className={classes.insuranceList}>Humana</td>
                    </tr>
                    <tr>
                      <td className={classes.insuranceList}>
                        Labor & Industries (Federal)
                      </td>
                      <td className={classes.insuranceList}>
                        Labor & Industries (WA State)
                      </td>
                      <td className={classes.insuranceList}>Medicaid</td>
                      <td className={classes.insuranceList}>Medicare</td>
                    </tr>
                    <tr>
                      <td className={classes.insuranceList}>
                        Molina Healthcare
                      </td>
                      <td className={classes.insuranceList}>Motor Vehicle</td>
                      <td className={classes.insuranceList}>NW Physicians</td>
                      <td className={classes.insuranceList}>PHCS</td>
                    </tr>
                    <tr>
                      <td className={classes.insuranceList}>PIP</td>
                      <td className={classes.insuranceList}>Primera</td>
                      <td className={classes.insuranceList}>Regence</td>
                      <td className={classes.insuranceList}>Secure Horizons</td>
                    </tr>
                    <tr>
                      <td className={classes.insuranceList}>Self Insured</td>
                      <td className={classes.insuranceList}>
                        Third Party is Accepted
                      </td>
                      <td className={classes.insuranceList}>Tricare</td>
                      <td className={classes.insuranceList}>
                        United Healthcare
                      </td>
                    </tr>
                  </table>{" "}
                  If your insurance company is on this list, please bring your
                  insurance info to your appointment. ​ Our list of providers
                  continues to grow to better serve our clients.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordionMobile}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="referrals-content"
                id="referrals"
                className={classes.accordionSummary}
              >
                <Typography variant="subtitle1">
                  New Patients, Referrals, Prescriptions
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Typography variant="body1">
                  We always welcome new patients whether from a referral of a
                  Doctor, an Existing Patient of Family Member. We are always
                  welcoming new patients! Please read below for more information
                  on scheduling your first visit.
                  <h4>Can I receive
                    PT services without a physician’s referral?</h4>
                  <p>
                    Consumers are
                    not required to have a referral in order to receive physical
                    therapy services in the State of Washington. Physical
                    Therapy services may be obtained without a referral if you
                    are a private pay or a cash-paying client receiving health
                    and wellness services.
                  </p>
                  <p>
                    In addition, some insurance companies
                    will pay for an initial evaluation and/or treatment without
                    referral for reimbursement. Our office can call and verify
                    your insurance benefits.
                  </p>
                  <h4>
                    To Make an Appointment
                  </h4>
                  <p>
                    Have your
                    doctor’s prescription available. If you haven’t seen your
                    doctor yet, feel free to contact us as you might not need a
                    referral for an initial evaluation, otherwise we can assist
                    in obtaining a referral for further treatment.
                  </p>
                  <p>
                    Have your
                    insurance information ready when you call or email the name
                    of your insurance company. For any questions, please email our Clinic Directors using the form below.
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Box>
      </Hidden>
    </div>
  );
}
