// links array
import ADPLogo from "../../photos/ExternalLogos/ADP.svg";
import PubMedLogo from "../../photos/ExternalLogos/PubMed.svg";
import hep2goLogo from "../../photos/ExternalLogos/hep2go.jpeg";
import medbridgeeducationLogo from "../../photos/ExternalLogos/medbridgeeducation.png";
import APTALogo from "../../photos/ExternalLogos/apta.svg";
import PTWALogo from "../../photos/ExternalLogos/ptwa.jpeg";
import transactionExpressLogo from "../../photos/ExternalLogos/transaction_express.png";
import NPILogo from "../../photos/ExternalLogos/npi_logo.jpeg";
import medicaidLogo from "../../photos/ExternalLogos/medicaid.jpeg";
import LandILogo from "../../photos/ExternalLogos/L&I.png";
import interpretingWorks from "../../photos/ExternalLogos/interpretingWorks.png";

// secret links array
import oneHealthLogo from "../../photos/ExternalLogos/one_health.svg";
import SMGLogo from "../../photos/ExternalLogos/SMG.png";

// files array
import DizzinessHandicap from "../../files/Employees_Dizziness Handicap Inventory.pdf";
import DynamicGait from "../../files/Employees_Dynamic-Gait-Index.pdf";
import FallsEfficacy from "../../files/Employees_Falls Efficacy Scale.pdf";
import LEFS from "../../files/Employees_LEFS.pdf";
import NeckIndex from "../../files/Employees_Neck Index.pdf";
import OswestryLowBackPain from "../../files/Employees_Oswestry-Low Back Pain Scale.pdf";
import QuickDash from "../../files/Employees_Quick Dash with calculator.pdf";
import TINETTI from "../../files/Employees_TINETTI.pdf";
import TMJIndex from "../../files/Employees_TMJ Index.pdf";
import PatientPainFunctionAssessment from "../../files/Employees/Patient Pain and Function Assessment.pdf";
import OwestryLowBackDisability from "../../files/Employees/Owestry Low Back Disability Questionnaire.pdf";
import NeckDisability from "../../files/Employees/Mior Cervical Spine Questionnaire.pdf";
import KneeInjury from "../../files/Employees/Koos Knee Injury & Osteoarthritis Questionnaire.pdf";
import HipDisability from "../../files/Employees/Hoos Hip Disability and Osteoarthritis Outcome.pdf";
import ShoulderASES from "../../files/Employees/ASES Shoulder Score.pdf";
import FootAndAnkle from "../../files/Employees/Foot & Ankle Disability Index FADI.pdf";


const linksArr = [
    {
        link: "https://workforcenow.adp.com/workforcenow/login.html",
        img: ADPLogo,
        alt: "ADP Logo",
        subtitle: "ADP payroll/timekeeping",
      },
      {
        link: "https://pubmed.ncbi.nlm.nih.gov/",
        img: PubMedLogo,
        alt: "PubMed Logo",
        subtitle: "PubMed",
      },
      {
        link: "https://www.hep2go.com/",
        img: hep2goLogo,
        alt: "hep2go Logo",
        subtitle: "hep2go",
      },
      {
        link: "https://www.medbridgeeducation.com/",
        img: medbridgeeducationLogo,
        alt: "MedBridge Logo",
        subtitle: "MedBridge",
      },
      {
        link: "https://www.apta.org/",
        img: APTALogo,
        alt: "APTA Logo",
        subtitle: "APTA",
      },
      {
        link: "https://aptawa.org/",
        img: PTWALogo,
        alt: "PTWA Logo",
        subtitle: "PTWA",
      },
      {
        link: "https://vt.transactionexpress.com/Default.aspx",
        img: transactionExpressLogo,
        alt: "Transaction Express logo",
        subtitle: "Transaction Express",
      },
      {
        link: "https://npiregistry.cms.hhs.gov/",
        img: NPILogo,
        alt: "NPI Logo",
        subtitle: "NPI",
      },
      {
        // link: "https://uls.force.com/customerlightningcommunity/s/login/",
        link: "https://www.cms.gov/",
        img: medicaidLogo,
        alt: "Medicaid logo",
        subtitle: "Medicaid",
      },
      {
        link: "https://www.interpreting.works/#!/top/dashboard",
        // link: "https://lni.wa.gov/",
        // img: LandILogo,
        img: interpretingWorks,
        alt: "L & I Logo",
        subtitle: "WA L&I Interpreting Works",
      },
]

const secretLinksArr = [
  {
    link: "https://www.onehealthport.com/sso",
    img: oneHealthLogo,
    alt: "One Health Logo",
    subtitle: "OneHealthPort~",
  },
  {
    link: "https://aerial.carecoordination.medecision.com/seoul/physician/LoginDefault.aspx?Logout=Y",
    img: SMGLogo,
    alt: "SMG Logo",
    subtitle: "SMG",
  }
]

const filesArr = [
  {
    file: DizzinessHandicap,
    label: "Dizziness Handicap Inventory"
  },
  {
    file: DynamicGait,
    label: "Dynamic Gait Index"
  },
  {
    file: FallsEfficacy,
    label: "Falls Efficacy Scale"
  },
  {
    file: LEFS,
    label: "LEFS"
  },
  {
    file: NeckIndex,
    label: "Neck Index"
  },
  {
    file: OswestryLowBackPain,
    label: "Oswestry Low Back Pain Scale"
  },
  {
    file: QuickDash,
    label: "Quick Dash (with calculator)"
  },
  {
    file: TINETTI,
    label: "TINETTI"
  },
  {
    file: TMJIndex,
    label: "TMJ Index"
  },
  {
    file: PatientPainFunctionAssessment,
    label: "Patient Pain & Function Assessment"
  },
  {
    file: OwestryLowBackDisability,
    label: "Owestry Low Back Disability Questionnaire"
  },
  {
    file: NeckDisability,
    label: "Neck Disability Index (Vernon & Mior)"
  },
  {
    file: KneeInjury,
    label: "Knee Injury (KOOS)"
  },
  {
    file: HipDisability,
    label: "Hip Disabilty (HOOS)"
  },
  {
    file: ShoulderASES,
    label: "ASES Shoulder Score"
  },
  {
    file: FootAndAnkle,
    label: "Foot & Ankle (FADI)"
  },
]

export const arrays = {linksArr, secretLinksArr, filesArr}