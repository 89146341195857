import React from "react";
import { OfficeTemplate } from "../OfficeTemplate";
import ClinicDirector from "../components/ClinicDirector";
import Biography from "../components/Biography";
import Location from "../components/Location";
import { TacomaData } from "./locations";

const { directorData, biographyData, locationData } = TacomaData;

function TacomaOffice() {
  return (
    <OfficeTemplate location="Tacoma" frontDeskEmail={locationData.officeEmail}>
      <ClinicDirector {...directorData} />
      <Biography {...biographyData} />
      <Location {...locationData} />
    </OfficeTemplate>
  );
}

export default TacomaOffice;
