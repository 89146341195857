import React from "react";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import '../blogs.css'

const useStyles = makeStyles(theme => ({
    categoryButton: {
        width: "100%",
        backgroundColor: "var(--blue)",
        color: "white",
        '&:hover': {
        backgroundColor: 'var(--green)'
    }
    },
    buttonGroup: {
        width: "100%"
    }
}))

function BlogsCategories() {
    const classes = useStyles()
    return (
    <div className="categories">
        <h2 id="categories-header">Categories</h2>
        <div className="category-buttons">
            <div>
            <ButtonGroup
                className={classes.buttonGroup}
                orientation="vertical"
                color="primary"
                aria-label="vertical contained primary button group"
                variant="contained"
            >
                <Link to="/blog" className="button">
                <Button className={classes.categoryButton}>All Blogs</Button>
                </Link>
                <Link to="/blog/categories/sports" className="button">
                <Button className={classes.categoryButton}>Sports</Button>
                </Link>
                <Link to="/blog/categories/exercises">
                <Button className={classes.categoryButton}>Exercises</Button>
                </Link>
                <Link to="/blog/categories/injuries">
                <Button className={classes.categoryButton}>Injuries</Button>
                </Link>
                <Link to="/blog/categories/employees">
                <Button className={classes.categoryButton}>Employees</Button>
                </Link>
                <Link to="/blog/categories/facts">
                <Button className={classes.categoryButton}>Facts</Button>
                </Link>
                <Link to="/blog/categories/food-and-nutrition">
                <Button className={classes.categoryButton}>Food and Nutrition</Button>
                </Link>
                <Link to="/blog/categories/footwear">
                <Button className={classes.categoryButton}>Footwear</Button>
                </Link>
            </ButtonGroup>
            </div>
        </div>
        </div>
    )
}

export default BlogsCategories;