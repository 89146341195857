import React from "react";
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import BlogHeader from "./BlogHeader";

const useStyles = makeStyles(theme => ({
  link: {
    color: "var(--green)",
    marginBottom: "30px",
  },
}));

export default function BlogOne() {
  const classes=useStyles()

  return (
    <div>
      <BlogHeader blogTitle="The Good Condition: The Benefits of a Physically Active Lifestyle" blogAuthor="By Jessica Miller"/>
      <Container>
        <br />
        <Typography variant="body2">
          The human body is a vessel that provides us with extraordinary abilities to think, feel,
          and move in ways that are seemingly limited only by our imaginations. Our bodies are put
          through stresses everyday. These stresses take their toll mentally, emotionally and physically.
          From commuting, working, choring, cleaning, taking care of kids, or other loved ones; we are in
          an almost continuous state of work and stress. It is important that we take care of all aspects of
          our lives whether they be Mental, Physical or Psychological. Today we will briefly discuss the
          Physical side of our lives and why it is important to maintain a healthy physical lifestyle to
          bolster our lives.
          </Typography>
        <Typography variant="body2">
          Promoting physical activity throughout your day can be beneficial for many reasons.
          Some of these reasons include strengthening your musculoskeletal system, which provides
          many benefits not limited to: decreasing fall risk, improving your mood and energy, decreasing
          stress, improving brain function, promoting better sleep, and strengthening your cardiovascular
          system. All of these benefits can help alleviate some chronic and acute health conditions while
          promoting healthy weight thereby increasing your overall quality of life. The importance and
          basis of a physical conditioning regimen was even a part of the, Plato’s, teachings.
          </Typography>
        <Typography variant="body2">
          It is recommended that physical activity for adults should be up to 30 minutes a day of
          moderate activity. This can be going for a brisk walk, hiking, biking, yard work, yoga, or even
          joining a gym and having a workout regimen with a health and fitness professional. These
          activities improve blood flow, increase delivery of oxygen and nutrients into vital tissues and
          organs, and release endorphins that help promote a good mood and improved brain function.
          </Typography>
        <Typography variant="body2">
          2020 and so far into 2021 have proven that, anything that can happen will happen.
          Nevertheless, we still have opportunities everyday to help ourselves, even when things are
          seemingly out of whack and hopeless. By maintaining and promoting a healthy lifestyle we can
          give ourselves the chance for the change in life we desire. Providing movement to our bodies
          can be one of the best medicines for the mind and body. Listening to our bodies and providing
          movement creates an improved quality of life now and for the future.
        </Typography>
        <Link className={classes.link} to='/blog'><Typography variant="h5">{'Return to Blogs'}</Typography></Link>
      </Container>
    </div>
  );
}
