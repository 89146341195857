import React from "react";
import { OfficeTemplate } from "../OfficeTemplate";
import ClinicDirector from "../components/ClinicDirector";
import Biography from "../components/Biography";
import Location from "../components/Location";
import { FederalWayData } from "./locations";
// import DirectorBio from "../../../files/Clinic_Dir_Bio_Andrew_Park_FedWay_040620.pdf";

const { directorData, biographyData, locationData } = FederalWayData;

function FederalWayOffice() {
  return (
    <OfficeTemplate
      location="Federal Way"
      frontDeskEmail={locationData.officeEmail}>
      <ClinicDirector {...directorData} />
      <Biography {...biographyData} />
      <Location {...locationData} />
    </OfficeTemplate>
  );
}

export default FederalWayOffice;
