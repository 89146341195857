import React, { useState } from "react";
import { Link } from "react-router-dom";
import NavbarLogo from "../photos/EPICLogos/originalLogo_long.svg";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import {
  AppBar,
  Toolbar,
  Container,
  List,
  ListItem,
  ListItemText,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import SideDrawer from "./SideDrawer";

const useStyles = makeStyles({
  navbarDisplayFlex: {
    display: `flex`,
  },
  navDisplayFlex: {
    display: `flex`,
    marginTop: "20px",
    justifyContent: `space-between`,
  },
  linkText: {
    textDecoration: `none`,
    textTransform: `uppercase`,
    color: `var(--blue)`,
    fontFamily: ["Lato", "sans-serif"].join(","),
    fontWeight: "900",
    height: "100px",
    lineHeight: "100px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "clip",
    minWidth: 130,
    width: 130,
    "&:hover": {
      backgroundColor: "#abe060",
      borderRadius: "10px",
    },
    // margin: "5px"
  },
  dropdownBtns: {
    textDecoration: `none`,
    textTransform: `uppercase`,
    color: `var(--blue)`,
    fontFamily: ["Lato", "sans-serif"].join(","),
    fontWeight: "900",
  },
  linkNoUnderline: {
    textDecoration: `none`,
  },
  background: {
    backgroundColor: "var(--green)",
  },
  imageBox: {
    width: "280px",
    marginBottom: "20px",
    marginTop: "20px",
    marginLeft: "20px",
    padding: "0 20px",
    justifyContent: `left`,
    "&:hover": {
      backgroundColor: "#abe060",
      borderRadius: "10px",
    },
  },
  imageBoxSm: {
    width: "230px",
    marginBottom: "20px",
    marginTop: "20px",
    marginLeft: "20px",
    justifyContent: `left`,
    padding: "0 20px",
    "&:hover": {
      backgroundColor: "#abe060",
      borderRadius: "10px",
    },
  },
  arrowDown: {
    border: "solid black",
    borderWidth: "0 3px 3px 0",
    display: "inline-block",
    padding: "3px",
    margin: "7px",
    marginBottom: "12px",
    transform: "rotate(45deg)",
    webkitTransform: "rotate(45deg)",
  },
  Tab: {
    minWidth: 100, // a number of your choice
    width: 100, // a number of your choice
  },
});

// const navLinks = [
//     { title: `covid-19`, path: `/covid` },
//     { title: `contact`, path: `/contact` },
//     { title: `locations`, path: `/office/Tacoma` },
//     { title: `testimonials`, path: `/testimonials` },
//     { title: `for patients`, path: `/patients` },
//     { title: `blog`, path: `/blog` }
// ];

const Header = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="sticky" color="default">
      <Toolbar className={classes.background}>
        <Hidden mdDown>
          <Grid justify={"space-between"} container>
            <Grid xs={2.5} item>
              <Link to="/">
                <img
                  className={classes.imageBox}
                  src={NavbarLogo}
                  alt="Epic Logo"
                />
              </Link>
            </Grid>
            <Grid xs={9.5} item>
              <Grid container justify={"center"}>
                <Tabs aria-label="Navigation Tabs">
                  <Tab className={classes.linkText} href="/" label={"home"} />
                  <Tab
                    className={classes.linkText}
                    href="/covid"
                    label={"covid-19"}
                  />
                  <Tab
                    className={classes.linkText}
                    href="/contact"
                    label={"contact"}
                  />
                  <Button
                    className={classes.linkText}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}>
                    locations <i className={classes.arrowDown}></i>
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}>
                    <Link
                      to="/office/tacoma"
                      className={classes.linkNoUnderline}>
                      <MenuItem
                        className={classes.dropdownBtns}
                        onClick={handleClose}>
                        Tacoma
                      </MenuItem>
                    </Link>
                    <Link
                      className={classes.linkNoUnderline}
                      to="/office/bellevue">
                      <MenuItem
                        className={classes.dropdownBtns}
                        onClick={handleClose}>
                        Bellevue
                      </MenuItem>
                    </Link>
                    <Link
                      className={classes.linkNoUnderline}
                      to="/office/federal-way">
                      <MenuItem
                        className={classes.dropdownBtns}
                        onClick={handleClose}>
                        Federal Way
                      </MenuItem>
                    </Link>
                    <Link
                      className={classes.linkNoUnderline}
                      to="/office/lakewood">
                      <MenuItem
                        className={classes.dropdownBtns}
                        onClick={handleClose}>
                        Lakewood
                      </MenuItem>
                    </Link>
                    <Link
                      className={classes.linkNoUnderline}
                      to="/office/olympia">
                      <MenuItem
                        className={classes.dropdownBtns}
                        onClick={handleClose}>
                        Olympia
                      </MenuItem>
                    </Link>
                    <Link
                      className={classes.linkNoUnderline}
                      to="/office/puyallup">
                      <MenuItem
                        className={classes.dropdownBtns}
                        onClick={handleClose}>
                        Puyallup
                      </MenuItem>
                    </Link>
                    <Link
                      className={classes.linkNoUnderline}
                      to="/office/sumner">
                      <MenuItem
                        className={classes.dropdownBtns}
                        onClick={handleClose}>
                        Sumner
                      </MenuItem>
                    </Link>
                  </Menu>
                  <Tab
                    className={classes.linkText}
                    href="/testimonials"
                    label={"testimonials"}
                  />
                  <Tab
                    className={classes.linkText}
                    href="/patients"
                    label={"for patients"}
                  />
                  <Tab
                    className={classes.linkText}
                    href="/blog"
                    label={"blog"}
                  />
                </Tabs>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden lgUp>
          <Grid xs={10} item>
            <Link to="/">
              <img
                className={classes.imageBoxSm}
                src={NavbarLogo}
                alt="Epic Logo"
              />
            </Link>
          </Grid>
          <Grid xs={2} item>
            <SideDrawer />
          </Grid>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
