const clinicLocations = [
    {
        clinic: "Tacoma",
        href: "/office/tacoma",
        frontDeskEmail: "epictacoma@epic-pt.com",
        address: "5006 Center St, Tacoma, WA 98409",
        phNum: "(253) 476-3333",
        director: "Norman Fabula"
    },
    {
        clinic: "Bellevue",
        href: "/office/bellevue",
        frontDeskEmail: "epicbellevue@epic-pt.com",
        address: "13353 Bel-Red Rd #103, Bellevue, WA 98005",
        phNum: "(425) 679-5996",
        director: "Erdemar Tayag"
    },
    {
        clinic: "Federal Way",
        href: "/office/federal-way",
        frontDeskEmail: "epicfw@epic-pt.com",
        address: "728 S 320th St Suite G, Federal Way, WA 98003",
        phNum: "(206) 212-6352",
        director: "Andrew Park"
    },
    {
        clinic: "Lakewood",
        href: "/office/lakewood",
        frontDeskEmail: "epiclakewood@epic-pt.com",
        address: "8511 S Tacoma Way Suite #303, Lakewood, WA 98499",
        phNum: "(253) 426-1244",
        director: "Angel Mabini"
    },
    {
        clinic: "Olympia",
        href: "/office/olympia",
        frontDeskEmail: "epicolympia@epic-pt.com",
        address: "3000 Limited Ln NW STE #100, Olympia, WA 98502",
        phNum: "(360) 292-7245",
        director: "Joey Jove"
    },
    {
        clinic: "Puyallup",
        href: "/office/puyallup",
        frontDeskEmail: "epicpuyallup@epic-pt.com",
        address: "16515 Meridian Ave E #202B, Puyallup, WA 98375",
        phNum: "(253) 466-3178",
        director: "Dana Krank"
    },
    {
        clinic: "Sumner",
        href: "/office/sumner",
        frontDeskEmail: "epicsumner@epic-pt.com",
        address: "5814 Graham Ave #101, Sumner, WA 98390",
        phNum: "(253) 891-7093",
        director: "TBD"
    },
]

export default clinicLocations