import React from "react";
import { Link } from "react-router-dom";
// import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import epicLogo from "../../../photos/EPICLogos/originalLogo_long.svg";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
// import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import { Autorenew } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    background: "white",
  },
  splash: {
    background:
      "var(--blue)",
    color: "white",
  },
  containerTwo: {
    background: "blue",
    display: "flex",
    justifyContent: "space-evenly",
  },
  covidHeader: {
    fontSize: "42px",
    fontWeight: "bold",
    display: "block",
    textAlign: "center",
  },
  covidSecondaryHeader: {
    display: "block",
    fontSize: "24px",
    // marginTop: "50px",
    textAlign: "center",
  },
  largeImportant: {
    color: "red",
    fontWeight: "bold",
    // textDecoration: "underline",
    backgroundColor:"var(--grey)",
    // paddingTop: "10px",
    // margin: "20px",
    fontSize: "18px",
    paddingBottom: "20px",
    marginBottom: "-5px",
  },
  image: {
    // backgroundImage: epicLogo
    width: "60%",
    marginBottom: "40px"
    // textAlign: "center",
    // marginRight: "auto",
    // marginLeft: "auto",
  },
  buttonOne: {
    background: "var(--grey)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px",
    color: "var(--blue)",
    height: 48,
    padding: "0 30px",
    textDecoration: "none",
    justifyContent: "center",
    fontSize: "20px",
    "&:hover": {
      // color: "#ab9387",
      background: "var(--blue)",
      color: "white",
      boxShadow: "0 3px 5px 2px var(--grey)",
    },
  },
  para: {
    width: "33%",
  },
  navButton: {
    width: "80%",
    marginTop: "40px",
    background: "var(--green)",
    borderRadius: 5,
    color: "black",
    padding: "0.5rem 3rem",
    textDecoration: "none",
    justifyContent: "center",
    textAlign: "center",
    "&:hover": {
      // color: "#ab9387",
      background:
      "#abe060",
    },
  },
  link: {
    color: "white",
    textDecoration: "none"
  }
}));

export default function Covid_Welcome() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h5" className={classes.largeImportant}>
          IF YOU ARE EXPERIENCING A LIFE-THREATENING EMERGENCY, CALL 9-1-1 FOR ASSISTANCE.
        </Typography>
      <div className={classes.splash}>
        <Container>
          <Box my={3}>
            <Grid container justify="center">
              <Typography variant="h2"><strong><span className={classes.covidHeader}>COVID-19 SAFETY & YOU</span></strong>
              <br />
              <span className={classes.covidSecondaryHeader}>Stay positive, but be (COVID) negative.</span>
              </Typography>
              {/* <Typography className={classes.covidSecondaryHeader} variant="h3">
                 Be Safe, Be Well, Be Aware of your surroundings.
                 <br />
                <span>Stay positive, but be (COVID) negative</span>
              </Typography> */}
                <a
                  href="https://86f49143-3b30-4a41-953b-7f6665121f99.filesusr.com/ugd/bac57f_dfc61d28903f4d12bc3267cd5004285d.pdf"
                  target="_blank"
                  rel="noreferrer noopener"
                  className={classes.navButton}>
                  <Typography style={{textAlign: "center"}} variant="subtitle1">
                    Download the Official CDC Covid-19 Safety Guidelines
                  </Typography>
                </a>
            </Grid>
          </Box>
        </Container>
      </div>
      {/* <Container>
        <Box my={3}>
          <Grid container display="flex" justify="center">
            <img className={classes.image} src={epicLogo} alt="logo" />
            <Grid
              container
              display="flex"
              justify="space-evenly"
              alignItems="center">
              <Typography variant="body2" className={classes.para}>
                We're here to help you Relax, Release, and Regain a Healthy
                Active Lifestyle through Personalized Physical Therapy.
              </Typography>
              <Link className={classes.link} to="/contact">
                <Button className={classes.buttonOne}>
                  Sign up
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container> */}
    </div>
  );
}
