import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
// import "./style.css";
// import Logo from './images/logoUpdate_stacked.svg';
import Logo from "../../../../photos/EPICLogos/hero-image.png";

const useStyles = makeStyles((theme) => ({
  // header: {
  //   display: "flex",
  //   margin: "0px",
  //   justifyContent: "center",
  // },
  header: {
    display: "flex",
    flexDirection: "column",
    // padding: "0px 40px",
    // boxSizing: "border-box",
    color: "var(--blue)",
    marginBottom: "30px",
    // border: "1px solid black",
    // width: "50%",
    // overflow: "hidden",
    // paddingLeft: "0px"
  },
  headerMobile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    // backgroundColor: "salmon",
    // padding: "0px 40px",
    // boxSizing: "border-box",
    color: "var(--blue)",
    marginBottom: "30px",
    // border: "1px solid black",
    // width: "auto",
    // overflow: "hidden",
    // paddingLeft: "0px"
  },
  tagline: {
    margin: "0px 10px",
    padding: "20px 0px",
    backgroundColor: "var(--grey)",
    width: "max(450px, 90%)",
    margin: "0 auto",
    textAlign: "center",
    borderRadius: "0 0 15px 15px",
    fontSize: "clamp(32px, 4vw, 36px)",
  },
  tagline: {
    margin: "0px 10px",
    padding: "20px 0px",
    backgroundColor: "var(--grey)",
    width: "max(450px, 90%)",
    margin: "0 auto",
    textAlign: "center",
    borderRadius: "0 0 15px 15px",
    fontSize: "clamp(32px, 4vw, 36px)"
  }
}));

function Header() {
  const classes = useStyles();
  return (
    <div>
      <Hidden xsDown>
        <div className={classes.header}>
          <img alt="Logo" src={Logo} id="logo" />
          <h1 className={classes.tagline}>
            <strong>
              Put Your Body & Well-Being in Good Hands
            </strong>
          </h1>
        </div>
      </Hidden>
      <Hidden smUp>
        <div className={classes.headerMobile}>
          <img alt="Logo" src={Logo} id="logo" />
          <h1 className={classes.tagline}>
            <strong>
              Put Your Body & Well-Being in Good Hands
            </strong>
          </h1>
        </div>
      </Hidden>
      {/* <img alt="Logo" src={Logo} id="logo" />
      <h1 className={classes.tagline}><strong>Put Your Body & Well-Being in Good Hands</strong></h1> */}
    </div>
  );
}

export default Header;
