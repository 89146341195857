import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BackToTopBtn from "./BackToTopBtn";
import clinicLocations from "../officeLocations";
import LocationRow from "./LocationRow"

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "4.5rem",
  },
  text: {
    textAlign: "center",
    marginBottom: "3rem",
  },
  table: {
    display: "flex",
    border: "1px solid white",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "center",
  },
  row: {
    border: "1px solid white",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    // alignItems: "center",
  },
  columnHeading: {
    border: "1px solid white",
    background: "var(--green)",
    color: "black",
    width: "20%",
    display: "flex",
    paddingTop: "10px",
    paddingBottom: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  itemGrey: {
    display: "flex",
    border: "1px solid white",
    background: "var(--grey)",
    width: "20%",
    justifyContent: "center",
    alignItems: "center",
  },
  itemBlue: {
    display: "flex",
    border: "1px solid white",
    background: "var(--blue)",
    color: "white",
    width: "20%",
    justifyContent: "center",
    alignItems: "center",
  },
  linkBlue: {
    color: "white",
    "&:visited": {
      color: "var(--cream)"
    },
    "&:hover": {
      background: "var(--cream)",
      color: "var(--green)"
    }
  },
  linkGrey: {
    color: "black",
    "&:visited": {
      color: "var(--cream)"
    },
    "&:hover": {
      background: "var(--cream)",
      color: "blue"
    }
  }
}));

export default function Locations() {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h3">Locations</Typography>
      <table className={classes.table}>
        <tr className={classes.row}>
          <th className={classes.columnHeading}>
            <Typography variant="subtitle1">Clinic</Typography>
          </th>
          <th className={classes.columnHeading}>
            <Typography variant="subtitle1">Front desk email</Typography>
          </th>
          <th className={classes.columnHeading}>
            <Typography variant="subtitle1">Address</Typography>
          </th>
          <th className={classes.columnHeading}>
            <Typography variant="subtitle1">Phone number</Typography>
          </th>
          <th className={classes.columnHeading}>
            <Typography variant="subtitle1">Clinic Director</Typography>
          </th>
        </tr>
        {clinicLocations.map((clinic, index) => {
          if (index % 2 === 0) {
            return <LocationRow color="grey" clinic={clinic} />
          } else {
            return <LocationRow color="blue" clinic={clinic} />
          }
        })}
      </table>
      <BackToTopBtn />
    </div>
  );
}
