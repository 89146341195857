import React from 'react'
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    photoContainer: {
        display: `flex`,
        justifyContent: "space-evenly",
        marginBottom: "15px",
        marginTop: "15px"
    },
    '50': {
        width: '50%'
    },
    navDisplayFlex: {
        display: `flex`,
        marginTop: "20px",
        justifyContent: `space-between`
    },
    Tab: {
        minWidth: 100, // a number of your choice
        width: 100, // a number of your choice
    }
});

export default function PhotoContainer(props) {
    const classes = useStyles()
    const width = props.width ? props.width : "100%"
    return (
        <div className={`${classes.photoContainer} ${classes.width}`}>
            {props.children}
        </div>
    )
}
