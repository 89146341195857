import React from "react";
import Welcome from "./components/Covid_Welcome";
import Guidelines from "./components/Covid_Guidelines";
// import { useSelector, useDispatch } from "react-redux";
// import { NAV_BTN } from "../../actions";

export default function COVID() {
  // const NavBtn = useSelector((state) => state.NavBtn);
  // const dispatch = useDispatch();

  // const DoNav = () => {
  //   if (NavBtn === true) {
  //     dispatch(NAV_BTN());
  //   }
  // };

  // DoNav();

  return (
    <div>
      <Welcome />
      <Guidelines />
    </div>
  );
}
