import React from "react";
import { useStyles } from "../OfficeTemplate";

export default function Location(props) {
  const classes = useStyles();
  const { googleMapsLink, address, officeEmail, officePhone, fax } = props;
  const mailTo = `mailto:${officeEmail}`;
  return (
    <div className={classes.info}>
      <h1 className={classes.header}>Location</h1>
      <p>
        <a
          className={classes.link}
          rel="noreferrer"
          target="_blank"
          href={googleMapsLink}>
          {address}
        </a>
      </p>
      {officeEmail ? (
        <p>
          <a href={mailTo} className={classes.link}>
            {" "}
            {officeEmail}{" "}
          </a>
        </p>
      ) : (
        <p>Email TBA</p>
      )}
      {officePhone ? <p>Ph: {officePhone}</p> : <p>Phone TBA</p>}
      {fax ? <p>Fax: {fax}</p> : <p>Fax TBA</p>}
    </div>
  );
}
