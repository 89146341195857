import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

export default function LogoLinks(props) {
  const useStyles = makeStyles((theme) => ({
    logoStyling: {
      width: "8rem",
      height: "8rem",
      background: "var(--grey)",
      margin: "0.5rem 3rem",
      padding: "1rem",
      borderRadius: "15px",
      "&:hover": {
        boxShadow: "0 3px 5px 2px var(--green)",
      },
    },
    subtitle: {
      textAlign: "center",
      backgroundColor: "white"
    },
  }));
  const classes = useStyles();
  const { link, img, alt, subtitle } = props.linkInfo;
  return (
    <div>
      <a href={link} target="_blank" rel="noreferrer noopener">
        <img className={classes.logoStyling} src={img} alt={alt} />
      </a>
      <Typography variant="body2" className={classes.subtitle}>
        {subtitle}
      </Typography>
    </div>
  );
}
