import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import LogoLinks from "./components/LogoLinks";
import { arrays } from "./employeeLinks";
import Typography from "@material-ui/core/Typography";
import FilesButtons from "./components/FilesButtons";

export default function Employees() {
  const [password, setPassword] = useState({
    secret: "",
  });
  const [links, setLinks] = useState(arrays.linksArr);
  const [secretLinks, setSecretLinks] = useState(arrays.secretLinksArr);
  const [filesLinks, setFilesLinks] = useState(arrays.filesArr);

  const employeePass = "EPICemployee";

  const handleInputChange = (event) => {
    setPassword({
      secret: event.target.value,
    });
  };

  const useStyles = makeStyles((theme) => ({
    container: {
      justifyContent: "space-between",
      margin: "0rem auto",
    },
    blueBackground: {
      background: "var(--blue)",
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      paddingTop: "4rem",
    },
    redBackground: {
      backgroundColor: "var(--cream)",
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
      paddingTop: "4rem",
    },
    heading: {
      padding: "20px 0px",
      backgroundColor: "var(--grey)",
      // width: "max(450px, 90%)",
      margin: "0 auto",
      marginBottom: "2rem",
      fontWeight: "200",
      /* font-style: "italic", */
      textAlign: "center",
      borderRadius: "0 0 15px 15px",
      fontSize: "clamp(32px, 4vw, 36px)",
      textDecoration: "none",
    },
    passwordBox: {
      margin: "auto",
      marginBottom: "8rem",
      padding: "2rem 1rem",
      fontSize: "2rem",
      display: "block",
      height: "3rem",
      width: "30%",
    },
    filesContainer: {
      marginBottom: "4rem",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
    },
    button: {
      alignSelf: "center",
      backgroundColor: "#8EC63F",
      height: "50px",
      fontSize: "18px",
      textTransform: "none",
      border: "3px solid #003860",
      borderRadius: "10px",
      padding: "10px 20px",
      margin: "10px",
      textTransform: "uppercase",
      "&:hover": {
        backgroundColor: "#abe060",
      },
    },
  }));

  const classes = useStyles();

  return (
    <div>
      <Container className={classes.container}>
        <Typography variant="h3">Helpful Links</Typography>
        <div className={classes.blueBackground}>
          {links.map((link) => {
            return <LogoLinks linkInfo={link} />;
          })}
        </div>
        {password.secret === employeePass ? null : (
          <h1 className={classes.heading}>
            <strong>Click Above to be Redirected</strong>
          </h1>
        )}
        {password.secret === employeePass ? (
          <div>
            <div className={classes.redBackground}>
              {secretLinks.map((link) => {
                return <LogoLinks linkInfo={link} />;
              })}
            </div>
            <h1 className={classes.heading}>
              <strong>Click Above to be Redirected</strong>
            </h1>
          </div>
        ) : (
          <div>
            <Typography variant="h3">
              Please enter the employee password below to view protected
              information
            </Typography>
            <input
              className={classes.passwordBox}
              type="password"
              onChange={handleInputChange}
            />
          </div>
        )}
        <div>
          <Typography variant="h3">Files</Typography>
          <div className={classes.filesContainer}>
            {filesLinks.map((file) => {
              return <FilesButtons file={file.file} label={file.label} />;
            })}
          </div>
        </div>
      </Container>
    </div>
  );
}
