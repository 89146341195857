import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

export default function FilesButtons({ file, label }) {

    const useStyles = makeStyles((theme) => ({
      link: {
        textDecoration: "none"
      },
        button: {
          alignSelf: "center",
          backgroundColor: "#8EC63F",
          height: "50px",
          fontSize: "18px",
          textTransform: "none",
          border: "3px solid #003860",
          borderRadius: "10px",
          padding: "10px 20px",
          margin: "10px",
          textTransform: "uppercase",
          "&:hover": {
            backgroundColor: "#abe060",
          },
        },
      }));
    
      const classes = useStyles();
  return (
    <a className={classes.link} href={file} target="_blank">
      <Button className={classes.button} variant="contained">
        {label}
      </Button>
    </a>
  );
}
