import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BackToTopBtn from "./BackToTopBtn";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    margin: "0px",
    justifyContent: "center",
  },
  answerBox: {
    display: "flex",
    flexDirection: "column",
    // border: "black 1px solid",
    justifyContent: "center",
    alignItems: "center",
    margin: "1.2rem auto",
    // padding: "2rem 2rem",
    // backgroundColor: "var(--grey)",
    // boxShadow: "0 3px 5px 2px var(--blue)",
    // borderRadius: "15px 15px 0px 15px",
    color: "black",
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
  questionBox: {
    display: "flex",
    flexDirection: "column",
    // border: "black 1px solid",
    justifyContent: "center",
    alignItems: "center",
    // margin: "2rem auto",
    // padding: "2rem 2rem",
    // backgroundColor: "var(--blue)",
    // boxShadow: "0 3px 5px 2px var(--grey)",
    borderRadius: "15px 15px 15px 0px",
    color: "black",
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
  textBox: {
    color: "white",
    backgroundColor: "var(--blue)",
    width: "100vph",
    textAlign: "center",
    padding: "30px",
    lineHeight: "1.3",
    fontSize: "x-large",
    margin: "auto",
    borderRadius: "15px",
  },
}));

export default function GetStarted() {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h4" className={classes.header}>
        GET STARTED
      </Typography>
      <Typography variant="h2" className={classes.header}>
        No Referral Needed
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        Our physical therapists are essential participants in the healthcare
        system, playing a critical role in the delivery of services that advance
        the health and well-being of our patients. Access to care and cost
        containment are important factors in the healthcare delivery system that
        play into direct access.
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        Washington State is a jurisdiction that allows, literally, direct access
        to physical therapy treatment services. This means that you are able to
        be evaluated and treated by a physical therapist without a prescription/referral from your physician or healthcare professional.
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        * For Insurance coverage, specific requirements from your insurance may
        not allow a Direct Access referral. Some insurances require a physician
        referral for coverage; we are able to help with the guidelines.
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        Physical therapists can now work as an integral member of the health
        care team and provide a cost-effective access point for patients with a
        wide variety of medical and musculoskeletal conditions that impact their
        ability to move and perform functional activities. Many conditions can
        be successfully evaluated and treated without expensive diagnostic
        testing. With healthcare costs rising, Epic Physical Therapy is striving
        to be part of the solution by giving patients rapid access to services,
        communicating with other healthcare providers involved in the care of
        the patient, and by providing a continuum of services to meet the needs
        of the population.
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        We recognize that you may be in pain, recovering from recent surgery, or
        uncertain about what to expect in physical therapy. Our goal is to make
        your first appointment as simple as possible.
      </Typography>
      <BackToTopBtn />
    </div>
  );
}
