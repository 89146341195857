import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import {Link, } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles({
    list: {
        width: 250
    },
    linkText: {
        textDecoration: `none`,
        textTransform: `uppercase`,
        color: `black`
    },
    dropdownBtns: {
        textDecoration: `none`,
        textTransform: `uppercase`,
        color: `var(--blue)`,
        fontFamily: ["Lato", "sans-serif"].join(","),
        fontWeight: "900",
    },
    linkNoUnderline: {
        textDecoration: `none`
    },
    arrowDown: {
        border: "solid black",
        borderWidth: "0 3px 3px 0",
        display: "inline-block",
        padding: "3px",
        margin: "7px",
        marginBottom: "12px",
        transform: "rotate(45deg)",
        webkitTransform: "rotate(45deg)"
    }
});

const SideDrawer = () => {
const classes = useStyles();
const [state, setState] = useState({ right: false });
const [anchorEl, setAnchorEl] = React.useState(null);

const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    toggleDrawer()
};

const handleClose = () => {
    setAnchorEl(null);
};

const toggleDrawer = (anchor, open) => event => {
    if (
    event.type === "keydown" &&
    (event.key === "Tab" || event.key === "Shift")
    ) {
    return;
    }

    setState({ [anchor]: open });
};

const sideDrawerList = anchor => (
    <div
    className={classes.list}
    role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    onKeyDown={toggleDrawer(anchor, false)}
    >
    <List component="nav">
        <a href="/" key="home" className={classes.linkText}>
            <ListItem button>
            <ListItemText primary="home" />
            </ListItem>
        </a>
        <a href="/covid" key="covid" className={classes.linkText}>
            <ListItem button>
            <ListItemText primary="covid" />
            </ListItem>
        </a>
        <a href="/contact" key="contact" className={classes.linkText}>
            <ListItem button>
            <ListItemText primary="contact" />
            </ListItem>
        </a>
        <Button className={classes.linkText} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <i className={classes.arrowDown}></i>locations
        </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Link to="/office/tacoma" className={classes.linkNoUnderline}>
                    <MenuItem className={classes.dropdownBtns} onClick={handleClose}>Tacoma</MenuItem>
                </Link>
                <Link  className={classes.linkNoUnderline} to="/office/bellevue">
                    <MenuItem className={classes.dropdownBtns}onClick={handleClose}>Bellevue</MenuItem>
                </Link>
                <Link  className={classes.linkNoUnderline} to="/office/federal-way">
                    <MenuItem className={classes.dropdownBtns}onClick={handleClose}>Federal Way</MenuItem>
                </Link>
                <Link  className={classes.linkNoUnderline} to="/office/lakewood">
                    <MenuItem className={classes.dropdownBtns}onClick={handleClose}>Lakewood</MenuItem>
                </Link>
                <Link  className={classes.linkNoUnderline} to="/office/olympia">
                    <MenuItem className={classes.dropdownBtns}onClick={handleClose}>Olympia</MenuItem>
                </Link>
                <Link  className={classes.linkNoUnderline} to="/office/puyallup">
                    <MenuItem className={classes.dropdownBtns}onClick={handleClose}>Puyallup</MenuItem>
                </Link>
                <Link  className={classes.linkNoUnderline} to="/office/sumner">
                    <MenuItem className={classes.dropdownBtns}onClick={handleClose}>Sumner</MenuItem>
                </Link>
            </Menu>
        <a href="/testimonials" key="testimonials" className={classes.linkText}>
            <ListItem button>
            <ListItemText primary="testimonials" />
            </ListItem>
        </a>
        <a href="/patients" key="for patients" className={classes.linkText}>
            <ListItem button>
            <ListItemText primary="for patients" />
            </ListItem>
        </a>
        <a href="/blog" key="blog" className={classes.linkText}>
            <ListItem button>
            <ListItemText primary="blog" />
            </ListItem>
        </a>
        {/* const navLinks = [
            { title: `covid-19`, path: `/covid` },
            { title: `contact`, path: `/contact` },
            { title: `locations`, path: `/office/Tacoma` },
            { title: `testimonials`, path: `/testimonials` },
            { title: `for patients`, path: `/patients` },
            { title: `blog`, path: `/blog` }
        ]; */}
    </List>
    </div>
);

return (
    <React.Fragment>
    <IconButton
        edge="start"
        aria-label="menu"
        onClick={toggleDrawer("right", true)}
    >
        <MenuIcon fontSize="large" style={{ color: `white` }} />
    </IconButton>

    <Drawer
        anchor="right"
        open={state.right}
        onOpen={toggleDrawer("right", true)}
        onClose={toggleDrawer("right", false)}
    >
        {sideDrawerList("right")}
    </Drawer>
    </React.Fragment>
);
};

export default SideDrawer;