import React from "react";
import Container from "@material-ui/core/Container";
import PersonCard from "./components/PersonCard";
import { team } from "./data";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    //   flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "white",
    flexWrap: "wrap",
    //   width: "50%",
  },
  blurb: {
    fontSize: "1.4rem",
    width: "75%",
    margin: "2rem auto"
  }
}));

export default function index() {
  const classes = useStyles();
  return (
    <Container>
      <p className={classes.blurb}>
        Creating this site would not have been possible without the hard work from these talented individuals! Please don't hesitate to reach out to anybody below if you have any questions about the development and maintanence of this site. We are all very excited to lend EPIC Physical Therapy our skills and expertise!</p>
      <div className={classes.container}>
        {team.map((person) => {
          return <PersonCard personInfo={person} />;
        })}
      </div>
    </Container>
  );
}
