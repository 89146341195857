import React from "react";
import "../blogs.css";
import BlogPhoto from "../../../photos/StockPhotos/blog-photo-1.png";

function BlogHeader(props) {
    return (
        <div className="blog-header">
            <img alt="Logo" src={BlogPhoto} id="logo" />
            <h1><strong>{props.blogTitle}</strong></h1>
            <h2><strong>{props.blogAuthor}</strong></h2>
        </div>
    );
}

export default BlogHeader;
