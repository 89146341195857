import React from "react";
import { useStyles } from "../OfficeTemplate";

export default function Biography(props) {
  const {
    biographyText1,
    biographyText2,
    biographyText3,
    biographyLink,
    biographyFile,
  } = props;
  const classes = useStyles();
  return (
    <div className={classes.bioContainer}>
      <h1 className={classes.header}>Biography</h1>
      {biographyText1 ? (
        <p className={classes.bio}>{biographyText1}</p>
      ) : (
        <p className={classes.bio}>Biography coming soon!</p>
      )}
      {biographyText2 ? <p className={classes.bio}>{biographyText2}</p> : null}
      {biographyText3 ? <p className={classes.bio}>{biographyText3}</p> : null}
      {biographyFile ? (
        <a
          className={classes.link}
          href={biographyFile}
          target="_blank"
          rel="noreferrer noopener">
          Extended Biography
        </a>
      ) : (
        <p className={classes.disabledLink} href="#">
          Extended Biography coming soon!
        </p>
      )}
    </div>
  );
}
