import React from "react";
import { OfficeTemplate } from "../OfficeTemplate";
import ClinicDirector from "../components/ClinicDirector";
import Biography from "../components/Biography";
import Location from "../components/Location";
import { LakewoodData } from "./locations";

const { directorData, biographyData, locationData } = LakewoodData;

function LakewoodOffice() {
  return (
    // <OfficeTemplate location="Lakewood" >
    <OfficeTemplate
      location="Lakewood"
      frontDeskEmail={locationData.officeEmail}>
      <ClinicDirector {...directorData} />
      <Biography {...biographyData} />
      <Location {...locationData} />
    </OfficeTemplate>
  );
}

export default LakewoodOffice;
