import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    margin: "0px",
    justifyContent: "center",
  },
  answerBox: {
    display: "flex",
    flexDirection: "column",
    // border: "black 1px solid",
    justifyContent: "center",
    alignItems: "center",
    margin: "1.2rem auto",
    // padding: "2rem 2rem",
    // backgroundColor: "var(--grey)",
    // boxShadow: "0 3px 5px 2px var(--blue)",
    // borderRadius: "15px 15px 0px 15px",
    color: "black",
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
  questionBox: {
    display: "flex",
    flexDirection: "column",
    // border: "black 1px solid",
    justifyContent: "center",
    alignItems: "center",
    // margin: "2rem auto",
    // padding: "2rem 2rem",
    // backgroundColor: "var(--blue)",
    // boxShadow: "0 3px 5px 2px var(--grey)",
    borderRadius: "15px 15px 15px 0px",
    color: "black",
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
  textBox: {
    color: "white",
    backgroundColor: "var(--blue)",
    width: "100vph",
    textAlign: "center",
    padding: "30px",
    lineHeight: "1.3",
    fontSize: "x-large",
    margin: "auto",
    borderRadius: "15px",
  },
}));

export default function TextSplash() {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h2" className={classes.header}>
        Welcome to EPIC Physical Therapy! We’re so happy you found us. Your road
        to a full recovery begins now, and we’re looking forward to working with
        you.
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        We are pleased that you have selected Epic Physical Therapy Health &
        Performance for your recovery and physical therapy needs. Our goal is to
        have functional and pain free again in as short amount of time possible,
        but the road to recovery is a process and based upon your injury or
        condition and current status, this process may take a few days to as
        long as a few months. Please let us know how we can serve you best since
        you are the reason why Epic Physical Therapy was founded. We hope you
        enjoy your time with us as we dedicate ourselves to helping you reach
        your full potential to recovery.
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        Please fill out the attached patient forms legibly, accurately, and
        completely. This information will be held in strict confidence in
        accordance with HIPAA as amended and is essential to ensure your
        understanding of our billing procedures, our determination of your
        physical therapy medical condition in developing your complete,
        individualized, and functional plan of care. You have access to your
        records upon request at any time (subject to record retention
        regulations). We will require up to 2-5 business days’ notice to comply
        with your request fully.
      </Typography>
      <Typography variant="h4" className={classes.questionBox}>
        Thank you!
      </Typography>
    </div>
  );
}
