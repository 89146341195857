import Header from "./components/Header"
import ScheduleAppointment from "./components/ScheduleAppointment"
import Philosophy from "./components/Philosophy"
import Treatments from "./components/Treatments"
import Services from "./components/Services"
import ContactForm from "../../components/ContactForm"
// import Testimonials from "./components/TestimonialsCarousel"

function HomePage() {
    return (
        <>
            <Header />
            <ScheduleAppointment />
            <Philosophy />
            <Treatments />
            <Services />
            {/* <Testimonials /> */}
            <ContactForm />
        </>
    )
}

export default HomePage;