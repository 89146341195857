import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import BackToTopBtn from "./BackToTopBtn";
import NewPatientForm from "../../../files/NewPatientForm.pdf";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    margin: "0px",
    justifyContent: "center",
  },
  answerBox: {
    display: "flex",
    flexDirection: "column",
    // border: "black 1px solid",
    justifyContent: "center",
    alignItems: "center",
    margin: "2rem auto",
    padding: "2rem 2rem",
    backgroundColor: "var(--grey)",
    boxShadow: "0 3px 5px 2px var(--blue)",
    borderRadius: "15px 15px 0px 15px",
    color: "black",
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
  questionBox: {
    display: "flex",
    flexDirection: "column",
    // border: "black 1px solid",
    justifyContent: "center",
    alignItems: "center",
    margin: "2rem auto",
    padding: "2rem 2rem",
    backgroundColor: "var(--blue)",
    boxShadow: "0 3px 5px 2px var(--grey)",
    borderRadius: "15px 15px 15px 0px",
    color: "white",
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
  textBox: {
    color: "white",
    backgroundColor: "var(--blue)",
    width: "100vph",
    textAlign: "center",
    padding: "30px",
    lineHeight: "1.3",
    fontSize: "x-large",
    margin: "auto",
    borderRadius: "15px",
  },
  link: {
    textDecoration: "none",
  },
  center: {
    textAlign: "center",
    justifyContent: "center",
    marginBottom: "30px",
  },
  button: {
    alignSelf: "center",
    backgroundColor: "#8EC63F",
    hieght: "50px",
    fontSize: "18px",
    textTransform: "none",
    border: "3px solid #003860",
    borderRadius: "10px",
    padding: "10px 20px",
    margin: "0px 40px",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: "#abe060",
    },
  },
}));

export default function FAQ() {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h2" className={classes.header}>
        Frequently Asked Questions
      </Typography>
      <Typography variant="h4">
        Here are some answers to the questions we get the most. If your question
        isn't answered, please contact us and we will get back to you as soon as
        we can.
      </Typography>

      <Typography variant="h4" className={classes.questionBox}>
        Q) How do I plan for my first appointment?
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        Call your insurance. It’s always best to know what your responsibility
        will be for any services. Double check that EPIC Physical Therapy and
        the location you’ve chosen is in-network. If you do not have insurance,
        we offer affordable self-pay options. Please call your clinic location
        to learn more.
      </Typography>
      <Typography variant="h4" className={classes.questionBox}>
        Q) What can physical therapy do for me?
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        Physical therapy can help restore function, improve mobility, relieve
        pain, and prevent or limit permanent physical disabilities.
      </Typography>
      <Typography variant="h4" className={classes.questionBox}>
        Q) What should I wear to physical therapy?
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        Please wear something comfortable and loose-fitting. If you have a lower
        extremity injury, bring or wear shorts. You will be doing different
        exercises, and we like our patients to be comfortable during their
        physical therapy session.
      </Typography>
      <BackToTopBtn />
      <Typography variant="h4" className={classes.questionBox}>
        Q) What should I bring?
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        You’ll need to bring a photo ID, your insurance card, a prescription for
        therapy from your doctor (if applicable), and your completed
        forms/paperwork from our website. Oh, and don’t forget a smile!
      </Typography>
      <a className={classes.link} href={NewPatientForm} target="_blank">
        <Button className={classes.button} variant="contained">
          Click Here to fill out a New Patient Form
        </Button>
      </a>
      <a className={classes.link} href={NewPatientForm} target="_blank">
        <Button className={classes.button} variant="contained">
          Chasquea aqui para completar una forma de paciente nuevo
        </Button>
      </a>
      <Typography variant="h4" className={classes.questionBox}>
        Q) What should I expect at my first visit?
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        Plan to arrive 10-15 minutes early for your first appointment to
        complete a few medical information forms. To save time, you can download
        and print the forms by clicking the green link above. We make every attempt to be as flexible as possible
        for all of our patients, but being on time is important. We will do our
        best to stay on time as well. When your appointment begins, you will have an
        examination with your physical therapist and will also spend time going
        over your symptoms.
      </Typography>
      <Typography variant="h4" className={classes.questionBox}>
        Q) Do you file claims with my insurance company?
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        Yes, as a courtesy to our patients we file claims to both primary and
        secondary insurance companies.
      </Typography>
      <BackToTopBtn />
      <Typography variant="h4" className={classes.questionBox}>
        Q) Does my insurance cover physical therapy?
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        We encourage our patients to contact their insurance company prior to
        the appointment with any benefit questions. As a courtesy we may help
        you contact your insurance company prior to you coming in for your
        initial evaluation. Some policies cover a percentage while others
        require a copay per visit.
      </Typography>
      <Typography variant="h4" className={classes.questionBox}>
        Q) How much will it cost if I don’t have insurance?
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        As a courtesy to patients that do not have insurance, we offer special
        cash rates for an initial evaluation and an adjusted rate for every
        visit thereafter. Please contact our office for up to date pricing information.
      </Typography>
      <Typography variant="h4" className={classes.questionBox}>
        Q) How long is each visit?
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        Typically, each visit will last 45-60 minutes, or more depending on the
        complexity of your condition. Time can vary depending on the type of
        treatment you are receiving.
      </Typography>
      <Typography variant="h4" className={classes.questionBox}>
        Q) How many visits will I be seen for?
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        This will be addressed during your initial evaluation; visits vary from
        person to person depending on the complexity of your medical condition.
        Your therapist will work with you following your evaluation to best
        outline a plan specific to your condition.
      </Typography>
      {/* <Typography variant="h4" className={classes.questionBox}>
        Q) How long will my appointments be?
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        A) Each appointment will take approximately 1 hr with the exception of
        the initial evaluation. If you have not completed a registration form
        prior to the appointment, please be 10-15 minutes early to allow
        completion of the form. CLICK THIS LINK feel free to print out and fill
        out the forms at home prior to the appointment.
      </Typography>

      <Typography variant="h4" className={classes.questionBox}>
        Q) What should I wear for my appointments?
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        A) It is recommended patients wear comfortable athletic wear with tennis
        shoes. This allows for therapists to easily access the targeted body
        regions during modalities. Bring a bottle of water for hydration if you
        would like (water machine included in the clinic).
      </Typography>

      <Typography variant="h4" className={classes.questionBox}>
        Q) I have a billing issue, what do I do?
      </Typography>
      <Typography className={classes.answerBox} variant="h6">
        A) Please contact the clinic that you were last seen in and we will
        direct you to the necessary personnel or contact our billing department
        at _______
      </Typography> */}

      <br />
      <br />
      <br />

      <Typography className={classes.textBox}>
        For further questions please contact the number associated with the
        clinic you would like to attend - see below.
      </Typography>
      <BackToTopBtn />
    </div>
  );
}
