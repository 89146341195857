import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  button: {
    alignSelf: "center",
    // backgroundColor: '#8EC63F',
    backgroundColor: "#608de0",
    fontSize: "18px",
    textTransform: "none",
    border: "3px solid #003860",
    borderRadius: "10px",
    padding: "10px 20px",
    display: "block",
    margin: "2rem auto",
    textTransform: "uppercase",
    "&:hover": {
      // backgroundColor: '#abe060'
      backgroundColor: "#b4dbdb",
    },
  },
}));

export default function BackToTopBtn() {
  const classes = useStyles();
  return (
    <div>
      <Button className={classes.button} variant="contained">
        <a href="#top">Back to top</a>
      </Button>
    </div>
  );
}
