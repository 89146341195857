import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./components/Header";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
// import tacoma1 from "../../photos/tacoma_clinic_1.webp";
// import tacoma2 from "../../photos/tacoma_clinic_2.webp";
// import tacoma3 from "../../photos/tacoma_clinic_3.webp";
// import { useSelector, useDispatch } from "react-redux";
// import { NAV_BTN } from "../actions";

const useStyles = makeStyles(theme => ({
  photoContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: "6em",
  },
  photoAndCaption: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
  },
  photoCaption: {
    marginTop: "0.5em",
  },
  info: {
    flex: "0 1 300px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "15px",
    backgroundColor: "var(--blue)",
    border: "var(--grey) solid 5px",
    color: "white",
    paddingTop: "12px",
  },
  link: {
    color: "var(--green)",
    textDecoration: "none",
  },
  disabledLink: {
    color: "var(--green)",
    textDecoration: "none",
    "&hover": {
      cursor: "not-allowed",
    },
  },
  director: {
    textDecoration: "underline",
    fontSize: 25,
  },
  directorContainer: {
    flex: "0 1 300px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "15px",
    backgroundColor: "var(--blue)",
    border: "var(--grey) solid 5px",
    color: "white",
  },
  directorContainerMobile: {
    flex: "0 1 100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "15px",
    // backgroundColor: "var(--blue)",
    backgroundColor: "salmon",
    border: "var(--grey) solid 5px",
    color: "white",
    width: "100%",
  },
  directorInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    textAlign: "center",
    margin: "15px",
    backgroundColor: "var(--blue)",
    color: "white",
  },
  bioContainer: {
    flex: "0 1 400px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "15px",
    backgroundColor: "var(--blue)",
    border: "var(--grey) solid 5px",
    color: "white",
    padding: "15px",
  },
  bio: {
    textAlign: "center",
    margin: "1em auto",
    textDecoration: "none",
  },
  surround: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    backgroundColor: "var(--green)",
    margin: "40px 20px",
    textAlign: "center",
  },
  surroundMobile: {
    backgroundColor: "var(--green)",
    margin: "40px 10px",
    textAlign: "center",
  },
}));

export default function OfficeTemplate(props) {
  const classes = useStyles();

  return (
    <div>
      <Hidden xsDown>
        <Container>
          <Header
            location={props.location}
            frontDeskEmail={props.frontDeskEmail}
          />
          <Grid
            container
            item
            direction="row"
            alignItems="flex-start"
            justify="center">
            <div className={classes.surround}>{props.children}</div>
          </Grid>
        </Container>
      </Hidden>
      <Hidden smUp>
        <Header
          location={props.location}
          frontDeskEmail={props.frontDeskEmail}
        />
        <Grid
          container
          item
          direction="row"
          alignItems="flex-start"
          justify="center">
          <div className={classes.surroundMobile}>{props.children}</div>
        </Grid>
      </Hidden>
    </div>
  );
}

export { OfficeTemplate, useStyles };
